
.delivery-option-panel {
    background-color: #DAE2D5;
    margin-top: 35px;
    padding:25px 0;
    text-align: center;
    .template {
        h3 {
            padding-bottom: 15px;
            font-size: 22px;
        }
        > .row {
            padding:0 15px;
            > div {
                .devlivert-option-icon {
                    margin-bottom: 10px;
                    svg {
                        width: 110px;
                        height: 110px;
                    }
                }
                .devlivert-option-desc {
                    line-height: 23px;
                    padding: 0 36px;
                }
            }
        }
    }
}

@media (max-width:991px) {
    .delivery-option-panel .template > .row > div {
        .devlivert-option-desc { 
            padding: 0;
        }
    }
}
@media (max-width:767px) {
    .delivery-option-panel .template {
        h3 {
            padding-right: 35px;
            padding-left: 35px;
            line-height: 31px;
        }
        > .row {
            > div {
                margin-bottom:30px;
                .devlivert-option-desc { 
                    padding: 0 35px;
                }
            }
            > div:last-child {
                margin-bottom: 0px;
            }
        }
    }
}