body.christmas {
	.hero .row{
		z-index: 1;
	}

	.push-container.header {
		// footer snow scence
		& > div.bootstrap-4:not(header) {
			// stars on the headings
			.mixed-case-highlights h2, .recommended h2,.media-content h2, .media-content ~ .media-content h2, .newsletter h2,.recently-viewed h2 {
				position: relative;
				&:before, &:after {
					content: '';
					display: inline-block;
					height: 25px;
					width: 25px;
					background-size: 25px;
					background-repeat: no-repeat;
					position: absolute;
					margin-top: 5px;
					@include media-breakpoint-down(sm){
						height: 35px;
						width: 35px;
						background-size: 35px 35px;
					}
				}

				&:before {
					margin-left: -40px;
					@include media-breakpoint-down(sm){
						margin-left: -45px;
					}
				}

				&:after {
					margin-left: 15px;
				}
			}
			.recommended,.media-content ~ .media-content ,.recently-viewed{
				h2{
					&:before , &:after {
						background-size: 25px 25px;
						margin-top:5px;
						@include media-breakpoint-down(sm){
							height: 30px;
							width: 30px;
							background-size: 30px 30px;
						}
						@media (max-width:320px){
							height: 28px;
							width: 28px;
							background-size: 28px 28px;
							margin-top:2px;
						}
					}
				}
			}

			.recommended h2{
				&:before { 
					// background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); 
					@media (max-width:320px){
						margin-left: -31px;
					}
				}
				&:after{
					// background-image: url('/images/uk/en/law/christmas2021/snowflake.png');
					@media (max-width:320px){
						margin-left: 2px;
					}
				}
			}

			.recently-viewed h2{
				&:before { 
					// background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); 
					@media (max-width:320px){
						margin-left: -31px;
					}
				}
				&:after{
					// background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); 
					@media (max-width:320px){
						margin-left: 2px;
					}
				}
			}
			// .media-content h2{ 
			// 	&:before, &:after{
			// 		background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); 
			// 	}
			// }
			// .media-content ~ .media-content {
			// 	h2{
			// 		&:before , &:after{
			// 			background-image: url('/images/uk/en/law/christmas2021/snowflake.png');
			// 		}	
			// 	}
			// }

			// .mixed-case-highlights h2:before { background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); }
			// .mixed-case-highlights h2:after { background-image: url('/images/uk/en/law/christmas2021/snowflake.png'); }

		}

		// stars on left and right of hero carousel
		.bootstrap-4 > section:first-of-type  > .container, .container.content,.product-detail-page .container,.bootstrap-4 #static-nli-banner .hero-container .container {
			@include media-breakpoint-up(sm) {
				position: relative;

				&:before, &:after {
					content: '';
					width: 216px;
    				height: 386px;
					background-size: contain;
					background-repeat: no-repeat;
					display: block;
					position: absolute;
					visibility: visible;
					top: 0px;
				}

				&:before {
					background-image: url('/images/uk/en/law/christmas2022/LW_tetris_1_flourish.png');
					left: -300px;
					top: 10px;
				}

				&:after {
					background-image: url('/images/uk/en/law/christmas2022/LW_tetris_1_flourish.png');
					right: -300px;
					top: 200px;
				}
			}
		}
		.bootstrap-4 #carousel-li .container{
			@include media-breakpoint-up(sm) {
				&:before, &:after {
					width: 430px;
					height: 393px;
				}
			}
		}

		&.homepage{
			.bootstrap-4 > section:first-of-type  > .container, .container.content,.bootstrap-4 #static-nli-banner .hero-container .container{
				&:before {
					background-image: url('/images/uk/en/law/christmas2022/LW_carousel_stars_flourish.png');
					left: -400px;
					top: 30px !important;
					@media (min-width:1024px) and (max-width: 1400px){
						left: -270px;
					}
					@media (min-width:1401px) and (max-width: 1600px){
						left: -360px;
					}
				}
		
				&:after {
					background-image: url('/images/uk/en/law/christmas2022/LW_carousel_stars_flourish.png');
					right: -420px;
					top: 25px !important;
					@media (min-width:1024px) and (max-width: 1400px){
						right: -270px;
					}
					@media (min-width:1401px) and (max-width: 1600px){
						right: -360px;
					}
				}
				&:before,&:after  {
					top: 0;
				}
			}
			.media-content .container{ 
				&:before{
					background-image: url('/images/uk/en/law/christmas2022/LW_tetris_1_flourish.png');
					left: 45px;
					margin-top: -75px;
					@media (min-width:2200px){
						left: 350px;
					}
					@media (min-width: 1300px) {
						left: 0;
						width: 240px;
					}
					@media (min-width:1800px){
						left: 191px;
					}
					@media (min-width:2400px){
						left: 400px;
					}
					@media (min-width:768px) and (max-width:992px) and (max-device-width : 1024px) and (orientation : landscape){
						left: 0;
					}
					@media only screen and (min-device-width: 450px) and (max-device-width: 1024px) and (orientation : portrait){
						background-image: none;
					}
				}
				&:after{
					content: "";
					background-image: url('/images/uk/en/law/christmas2022/LW_tetris_1_flourish.png');
					right: 45px;
					position: absolute;
					display: inline-block;
					margin-top: -320px;
					@media (min-width:2200px){
						right: 350px;
					}
					@media (min-width:1800px){
						right: 191px;
					}
					@media (min-width:2400px){
						right: 400px;
					}
					@media (min-width: 1300px) {
						right: 0;
						width: 240px;
					}
					@media (min-width:768px) and (max-width:992px) and (max-device-width : 1024px) and (orientation : landscape){
						right: 0;
					}
					@media (min-width:768px) and (max-device-width : 1024px) and (orientation : portrait){
						visibility: hidden;
					}
				}
				&:before, &:after {
					width: 216px;
					height: 386px;
					background-size: contain;
					background-repeat: no-repeat;
					display: block;
					position: absolute;
					visibility: visible;
					background-position: center;
					z-index: 5;
				}
			}
			.tetris-bottom-section .media-content .container{
				&:before, &:after{
					background-image: url('/images/uk/en/law/christmas2022/LW_tetris_2_flourish.png');
					@media only screen and (min-device-width: 450px) and (max-device-width: 1024px) and (orientation : portrait){
						background-image: none;
					}
				}
				&:before{
					margin-top: 320px;
				}
				&::after{
					margin-top: -570px;
				}
			}
			section.media-content div[class^='block-']{
				z-index: 6;
			}
			// .media-content .container ~ .media-content .container, .giftbanner ~ .media-content .container{
			// 	&:before{
			// 		background-image: url('/images/uk/en/law/christmas2021/lw-ba-bbc-light-grey-tetris2-left.png'); 
			// 	}
			// 	&:after{
			// 		background-image: url('/images/uk/en/law/christmas2021/lw-ba-bbc-light-grey-tetris2-right.png');
			// 	}
			// }
		}

		// section.footer-links {
		// 	background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_Footer-xs.png');
		// 	background-size: 100% auto;
		// 	@include media-breakpoint-up(sm) {
		// 		background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_Footer.png');
		// 		background-size: auto 150%;
		// 	}
		// 	background-repeat: no-repeat;
		// 	background-position: bottom center;
		// }
	}

	//style for BA and BBC
	&.ba,&.bbc{
		.push-container.header {
			& > div.bootstrap-4:not(header) {
				.recommended h2, .newsletter h2, .recently-viewed h2{
					padding:10px 0;
				}
				.recommended h2, .newsletter h2, .media-content h2, .recently-viewed h2{
					&:before , &:after {
						margin-top: 0;
					}
				}
			}
		}
	}

	&.bbc{
		.push-container.header {
			& > div.bootstrap-4:not(header) {
				.recommended h2, .newsletter h2, .media-content h2, .recently-viewed h2{
					&:before , &:after {
						@media (max-width:767px){
							margin-top:0;
						}
					}
				}

				@media (max-width:767px){
					.recommended h2 , .recently-viewed h2{
						&:before{
							margin-left:-35px;
						}
						&:after{
							margin-left:5px;
						}
					}
					.newsletter h2 {
						&:before , &:after {
							display: none;
						}
					}
				}

				@media (max-width:400px){
					.recommended h2, .recently-viewed h2{
						&:before{
							margin-left:-30px;
						}
						&:after{
							margin-left:0;
						}
					}
				}
			}
		}
	}

	&.ba{
		.push-container.header {
			& > div.bootstrap-4:not(header) {
				.recommended h2, .recently-viewed h2{
					@media (max-width:767px){
						&:before , &:after {
							background:none;
						}
					}
				}
			
				@media (max-width:400px){
					.media-content h2{
						&:before , &:after {
							background:none;
						}
					}
				}
			}
		}
	}

	// styles for STWC
	&.stw{
		.push-container.header {
			&.homepage{
				.bootstrap-4 > section:first-of-type  > .container, .container.content,.bootstrap-4 #static-nli-banner .hero-container .container{
					&:before, &:after {
						top: 30px;
					}
					&:before {
						background-image: url('/images/uk/en/law/christmas2022/Left_Pattern.png');
						left: -420px;
					}
			
					&:after {
						background-image: url('/images/uk/en/law/christmas2022/Right_Pattern.png');
						right: -420px;
					}
				}
				.hero-container{
					background-color: rgb(186, 158, 102);
				}
				.media-content .container{ 
					&:before{
						background-image: url('/images/uk/en/law/christmas2022/Snowflake_snow_Tetris-1_flourish_STWC.png');
						margin-top: 65px;
					}
					&:after{
						content: "";
						background-image: url('/images/uk/en/law/christmas2022/Snowflake_snow_Tetris-1_flourish_STWC.png');
						transform: scaleX(-1);
						margin-top: -160px;
					}
					&:before, &:after {
						background-size: cover;
						height: 180px;
						@media only screen and (min-device-width: 450px) and (max-device-width: 1200px){
							background-image: none !important;
						}
					}
				}
				.media-content .container ~ .media-content .container{
					&:before{
						background-image: url('/images/uk/en/law/christmas2021/wc-stars-darkgrey-tetris2-left.png'); 
					}
					&:after{
						background-image: url('/images/uk/en/law/christmas2021/wc-stars-darkgrey-tetris2-right.png');
					}
				}
				.tetris-bottom-section .media-content .container{
					&:before, &:after{
						background-image: url('/images/uk/en/law/christmas2022/Branch_flourish_Tetris_2_STWC.png');
						@media only screen and (min-device-width: 450px) and (max-device-width: 1024px) and (orientation : portrait){
							background-image: none !important;
						}
					}
					&::after{
						transform: rotate(90deg) scale(-1);
					}
				}
			}
		}
		header#new-homepage-test-header{
			&.is-sticky{
				#main-navigation{
					.nav-top-level .nav-item a{
						padding: 0 5px;
					}
					.christmasnav{
						padding-left: 15px;
						@media screen and (min-width:768px) and (max-width:1024px) {
							padding-left: 8px;
						}
					}
				}
			}
			ul#showFullLists {
				background: #ffffff !important;
			}
		}
		
	}

//styles for averys
	&.avy{
		.push-container.header{
			&.homepage{
				.media-content .container{
					&:before, &:after {
						background-image: none;
					}
				}
				@include media-breakpoint-up(sm) {
					.bootstrap-4 > section:first-of-type  > .container, .container.content,.product-detail-page,  .bootstrap-4 > section:first-of-type  > .container, .container.content{
						&:before {
							background-image: url('/images/uk/en/law/christmas2022/presentwine-left.png');
							left:-140px;
						}
				
						&:after {
							background-image: url('/images/uk/en/law/christmas2022/presentwine-right.png');
							right:-140px;
						}
						&:before,&:after{
							width: 95px;
							height: 175px;
							top: 110px;
							background-size: 95px 175px;
							position: absolute;
						}
					}
				}
				header .masthead{
					&:after{
						background-image: url('/images/uk/en/law/christmas2022/box.png');
						width: 70px;
						height: 83px;
						position: absolute;
						visibility: visible;
						background-size: contain;
						background-repeat: no-repeat;
						right: -90px;
						top: 23px;
						@media(max-width:767px) {
							background: none;
							width: 0;
							height: 0;
							visibility: hidden;
						}
					}
				}
				header.is-sticky{
					.masthead{
						&:after{
							width: 30px;
							height: 30px;
							top: 4px;
							right: -40px;
							@media(max-width:767px) {
								background: none;
								width: 0;
								height: 0;
								visibility: hidden;
							}
						}
					}
				}
			}

			& > div.bootstrap-4:not(header) {
				.recommended h2,.media-content h2,.mixed-case-highlights h2, .recently-viewed h2{
					&:before,&:after  {
						background-size: contain;
						margin-top:-14px;
						width: 56px;
						height: 60px;
					}				
					&::before{
						background-image: url('/images/uk/en/law/christmas2022/present-left.png');
						margin-left: -86px
					}
					&::after{
						background-image: url('/images/uk/en/law/christmas2022/present-right.png');
					}
				}

				@media(max-width:767px) {
					.mixed-case-highlights h2{
						&:before {
							margin-left:-45px;
						}
						&:after  {
							margin-left:10px;
						}
						&:before,&:after{
							width: 40px;
						}
					}
				}

				@media(max-width:400px) {
					.mixed-case-highlights h2{
						&:before,&:after{
							margin-top:-3px;
							height: 30px;
							width: 30px;
						}
						&:before {
							margin-left:-35px;
						}
						&:after  {
							margin-left:0;
						}
					}
				}

				@media (max-width:360px){
					.mixed-case-highlights h2{
						&:before,&:after  {
							background-image: none;
						}
					}
				}
		
				@include media-breakpoint-up(sm) {
					.newsletter h2 { 
						&:before,&:after  {
							margin-top: -10px;
							background-size: contain;
							width: 144px;
							height: 40px;
						}
						&::before{
							background-image: url('/images/uk/en/law/christmas2022/present-left.png');
							margin-left: -86px;
						}
						&::after{
							background-image: url('/images/uk/en/law/christmas2022/present-right.png');
						}
					}
				}
			}

			.bootstrap-4 > section:first-of-type  > .container, .container.content,.product-detail-page .container{
				&:before {
					background-image: url('/images/uk/en/law/christmas2022/av-left-flourishes.png');
					left:-180px;
				}
		
				&:after {
					background-image: url('/images/uk/en/law/christmas2022/av-right-flourishes.png');
					right:-180px;
				}
				&:before, &:after {
					width: 143px;
					top: 10px;
				}
			}
			.bootstrap-4 {
				section.gift-cards{
					.container{
						&::before, &::after{
							background-image:none;
						}
					}
				} 
			}
			section.footer-links {
				background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_Footer-xs.png');
				background-size: 100% auto;
				@include media-breakpoint-up(sm) {
					background-image: url('/images/uk/en/law/offers/christmas/Homepage_Christmas_Footer.png');
					background-size: auto 150%;
				}
				background-repeat: no-repeat;
				background-position: bottom center;
			}
		}
	}
	header#new-homepage-test-header.is-sticky{
		#main-navigation{
			.nav-top-level .nav-item a{
				padding: 0 5px;
			}
			.christmasnav{
				padding-left: 10px;
				@media screen and (min-width:768px) and (max-width:1024px) {
					padding-left: 3px;
				}
			}
		}
	}
}


//Black friday homepage style 
.law, .stw, .ba, .bbc {
	&.black-friday{
		.push-container.header {
			.media-content.top-tetris {
				background-color: #000;
				h2{
					color: #fff!important;
					&:before, &:after{
						background-image: unset; 
					}
				}
			}
			
			.media-content ~ .media-content {
				background-color: #fff;
				h2{
					color:inherit!important;
					
					// &:before , &:after{
					// 	background-image: url('/images/uk/en/law/christmas2021/snowflake.png');
					// }	
					
				}
			}

			// .bootstrap-4 .hero {
			// 	&.black_friday_background{
			// 		background-image: url('/images/uk/en/law/christmas2020/Yellow_Splashes.png');
			// 		background-size: contain;
			// 		.container, .container.content,.product-detail-page .container {
			// 			&:before , &:after {
			// 				background-image: unset;
			// 				display: none;
			// 			}
			// 		}
			// 	}
			// 	.black_friday{
			// 		.content {
			// 			background-color: hsl(48, 100%, 60%);
			// 		}
			// 		&.selected{
			// 			.content{
			// 				background-color: #ffcd01;
			// 			}
			// 		}
			// 	}
			// }
		}
		header#new-homepage-test-header.is-sticky{
			#main-navigation{
				.nav-top-level .nav-item a{
					padding: 0 5px;
				}
				.christmasnav{
					padding-left: 10px;
					@media screen and (min-width:768px) and (max-width:1024px) {
						padding-left: 3px;
					}
				}
			}
		}
	}
}
body.stwc-black-friday{
	&.stw{
		header#new-homepage-test-header{
			&.is-sticky{
				#main-navigation{
					.nav-top-level .nav-item a{
						padding: 0 5px;
					}
					.christmasnav{
						padding-left: 15px;
						@media screen and (min-width:768px) and (max-width:1024px) {
							padding-left: 8px;
						}
					}
				}
			}
			ul#showFullLists {
				background: #ffffff !important;
			}
		}
	}
}
.law {
	&.christmas{
		.nav-item.nav-gifts a {
			color: #117b53;
			font-weight: bold;
		}
	}
}
.stw {
	&.christmas{
		a.gifts.fullstory-nav-gifts {
			color: #18425a;
			font-weight: bold;
		}
	}
}