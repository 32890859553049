/**
 * This SaSS is designed for the variation testing of the two headers on laithwaites
 * it will show a loading spinner before the optimizely test is loaded which
 * will them animate the header coming in.
 */
@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

#header-variations {

	transition-property: height;
	transition-duration: 0.3s;

	@include media-breakpoint-up(md) {
		&.loading {
			height: 213px;
		}
	}

	#header-variation-1, #header-variation-2 {
		display: none;
		animation-name: slideInDown;
		animation-duration: 0.3s;
	}

	&.show-variation-1 {
		#header-variation-1 {
			display: block;
		}

		.navbar.navbar-default { 
			// border-bottom: 5px solid #88223F; 
		}

		@include media-breakpoint-down(sm) {
		}

		@include media-breakpoint-up(md) {
			height: 213px;
		}
	}

	&.show-variation-2 {

		@include media-breakpoint-up(md) {
			//height: 195px;
		}

		#header-variation-2 {
			display: block;

			@include media-breakpoint-down(sm) {
			}

			@include media-breakpoint-up(md) {
				height: 175px;
			}
		}
	}
}

#content-variations {
	#content-variation-1, #content-variation-2 {
		display: none;
	}

	&.show-variation-1 {
		#content-variation-1 {
			display: block;
		}
	}

	&.show-variation-2 {
		#content-variation-2 {
			display: block;
		}
	}
}

#footer-variations {

	#footer-variation-1, #footer-variation-2 {
		display: none;
	}

	&.show-variation-1 {
		#footer-variation-1 {
			display: block;
		}
	}

	&.show-variation-2 {
		#footer-variation-2 {
			display: block;
		}
	}
}

.push-container {
	overflow-x: inherit;
}

// UXE-3977 - force the featured wine to be a font size of 17px;
h4.headline-featuredwine {
	font-size: 17px !important;
}