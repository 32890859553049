#hsbc-card-overlay{
    .modal-dialog{
        margin: 0;
    }
    .modal-lg {
        width: 100%;
    }
    .modal-header{
        display: none;
    }
    .modal-body{
        padding: 20px 0;
    }
    .hsbc-bg{
        background: white;
        font-family: "Roboto",Arial,sans-serif;
        color: #333333;
      }
    .hsbc-overlay {
        background: #ecedef;
        padding: 0 !important;
        @media (min-width: 992px){
          .container{
            width: 100%;
          }
          width: 100%;
        }
    }
    .help-link{
      @include media-breakpoint-down(sm){
        float: none !important;
        text-align: center;
      }
    }
    .hsbc-logo {
      position: absolute;
      top: 26px;
      left: 50%;
      margin-left: -122px;
      width: 270px;
      @include media-breakpoint-down(sm){
        top: 65px;
      }
    }
    .intro-text{
      font-size: 26px;
      text-align: center;
      margin: 40px 0 25px;
      font-family: "Roboto",sans-serif;
      font-weight: 300;
      @include media-breakpoint-down(sm){
        font-size: 22px;
      }
    }
    .hsbc-card{
      text-align: center;
      img{
        @include media-breakpoint-down(sm){
          width: 75%;
        }
      }
    }
    .hsbc-card-popup {
      background-color: white;
      width: 80%;
      margin: auto auto 80px;
      margin-top: 50px;
      box-shadow: 5px 2px 8px #ccc;
      padding: 40px 40px 70px;
      position: relative;
      @include media-breakpoint-down(md){
        width: 90%;
        padding: 40px 20px 70px;
      }
      @include media-breakpoint-down(sm){
        margin-top: 0;
        padding: 25px 16px 70px;
        width: 100%;
      }
      h4{
        font-family: "Roboto",sans-serif;
      }
      .cnn{
        width: 30px;
        height: 40px;
        padding: 5px;
        text-align: center;
        margin-right: 5px;
        border: 1px solid;
        @include media-breakpoint-down(sm){
          width: 20px;
          height: 25px;
          padding: 1px;
          margin-right: 1px;
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      .margin-30-right{
        margin-right: 30px;
        @include media-breakpoint-down(sm){
          margin-right: 10px;
        }
      }
      .cnn-star{
        font-size: 36px;
        font-weight: bold;
        padding: 0;
        border: 0;
        width: 20px;
        vertical-align: text-top;
        padding-bottom: 10px;
        @include media-breakpoint-down(sm){
          font-size: 16px;
          width: 8px;
        }
      }
      .hsbc-line{
        width: 100%;
      }
      .hsbc-form{
        text-align: center;
        .hsbc-line{
          border-top: none;
        }
      }
      .continue-ctn{
        @include media-breakpoint-down(sm){
          text-align: center;
        }
      }
      .btn-primary.hsbc-continue {
        background: #db0011 !important;
        color: #fff;
        border: none !important;
        padding: 7px;
        width: 100px;
        float: right;
        &:hover{
          background: #93000B !important;
        }
        @include media-breakpoint-down(sm){
          float: none;
          margin-top: 15px;
        }
      }
      .hsb-modal-error{
          display: none;
          text-align: left;
          padding: 0;
          margin: 0;
          width: 100%;
          color: #d7363b;
          background: transparent;
          border: 0;
          @include media-breakpoint-down(sm){
            margin: 10px;
          }
          &:before{
            content: "i";
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background: #d7363b;
            border-radius: 50%;
            color: #fff;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            position: relative;
            top: -2px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 100;
            margin-right: 1rem;
          }
      }
    }
    section.guarantee{
      border-top: 1px solid #dddddd;
      box-shadow: 5px 2px 8px #ccc;
      svg{
        height: 51px;
        .cls-3 {
            fill: #191919 !important;
        }
      }
      
    }
}
.hsb {
  section.guarantee {
    .trustpilot svg {
      height: 51px;
    } 
  }
}
header#new-homepage-test-header .masthead .sticky-head{
  display: none;
}
header#new-homepage-test-header.is-sticky .masthead .sticky-head{
  display: block;
}
header#new-homepage-test-header.is-sticky .masthead #user-logged-in-hsb{
  &:hover{
    .dropdown{
      display: block;
    }
  }
}
