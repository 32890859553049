section.guarantee {
	background: $white;
	.row {
		display: flex !important;
    	flex-wrap: wrap !important;
	}
	padding-bottom: 1.3em;
	padding-top: 2em;

	text-align: center;

	svg {
		display: block;
		margin: 10px auto;
	}

	.money-back svg { height: 50px; width: 51px; }
	.free-delivery svg { height: 51px; width: 103px; }
	.customer-service svg { height: 51px; width: 57px; }
	.trustpilot{ 
		img { 
			height: 51px; width: 152px;margin:10px auto;
			@include media-breakpoint-up(lg) {margin: 10px 18px;} 
			@include media-breakpoint-down(md) {margin: 10px -10px;}
			@include media-breakpoint-down(sm) {margin: 10px -8px;}
			@media (min-width: 320px) and (max-width: 358px) {
				margin: 10px -20px;
			}
		}
		span{
			&.visible-stw{
				display:none;
			}
		}
	}
}

.stw{
	section.guarantee{
		.trustpilot{
			.visible-stw{
				display: block;
				img{
					width: 152px;
    				height: 51px;
				}
			}
			.hidden-stw{
				display: none;
			}
		}
	}
}
.avy{
	section.guarantee{
		.trustpilot{
			.hidden-avy{
				display: none;
			}
		}
	}
}

