section.partnership-content,
.section.intro {
  padding: 3em 0;
  position: relative;
  border-bottom: 1px solid #ddd;

  h2 {
    @include font-heading(1.4em);
    text-align: center;
    margin-bottom: 20px;
  }

  @supports (clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px)) {
    &::after {
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(
        to top,
        rgba(221, 221, 221, 0) 0%,
        #dddddd 50%,
        #dddddd 100%
      );
      border-bottom-left-radius: 100% 100%;
      border-bottom-right-radius: 100% 100%;
      border-top-left-radius: 100% 100%;
      border-top-right-radius: 100% 100%;
      -webkit-clip-path: polygon(0 40px, 100% 40px, 100% 100%, 0 100%);
      clip-path: polygon(0 40px, 100% 40px, 100% 100%, 0 100%);
    }
  }

  img {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    img {
      object-fit: cover;
      position: absolute;
    }
  }
}
