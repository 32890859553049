section.mixed-case-highlights {

	.details {
		display: flex;
		flex-direction: column;

		.text {
			flex-grow: 1;
		}
	}

	background-color: #f5f5f5;
	text-align: center;
	padding: 2.5em 0 3em;
	img{
		@media(min-width:768px) and (max-width:991px){
			width:100%;
		}
	}
	h2 {
		@include font-heading(2em);
		text-align: center;
		margin-bottom: 10px;

		@include media-breakpoint-only(md) {
			font-size: 1.7em;
		}
	}

	h4 {
		font-weight: bold;
		color: #6e273d !important;
		@include font-body(1.6rem);
	}

	.btn {
		background: $header-primary;
	}
}
.avy.homepage {
	section.mixed-case-highlights img {
		aspect-ratio: 1 / 1;
	}
	section.mixed-case-highlights .btn-secondary{
		background-color: #ffffff;
		&:hover {
			background-color: #285B42;
		}
	}
	section.mixed-case-highlights {
		h2 {
			font-family: 'Libre Caslon Text', sans-serif;
			font-size: 24px;;
			@media (max-width:960px) {
				font-size: 22px;
			}
		}
	
		h4 {
			font-family: 'Libre Caslon Text', sans-serif;
			font-size: 16px;
		}
	}
}