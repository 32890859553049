/**
 * Sticky Nav
 */
&.is-sticky {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	@include media-breakpoint-down(sm) {
		&.is-sticky {
			height: 40px;
		}
	}
	&.searchOpen {
		height: 48px;
	}

	@include media-breakpoint-up(md) {
		div.logo,
		#checkout-link {
			display: none !important;
		}
	}
	div.logo {
		overflow: hidden;
		z-index: 1;

		@include media-breakpoint-down(sm) {
			a {
				background: url("/images/uk/en/homepage/logos/lw-logo-square.svg");
				height: 47px;
				width: 47px;
				//	transition: all 50ms ease-in-out;

				svg {
					display: none;
				}
			}

			position: absolute;
			left: 50%;
			margin-left: -23.5px;
			top: 0;
			z-index: 99;
		}
	}

	.stores-link {
		display:none !important;
	}

	.masthead {
		@include media-breakpoint-up(md) {
			height: 0;

			.help-link {
				display: none;
			}

			#account,
			#cart {
				position: absolute !important;
				top: 0px;
				right: 0;
				z-index: 1;
				width: auto;
				height: 40px;
				padding: 0;
				background: none;
				text-align: left;
				margin: 6px 10px 0;
				@include media-breakpoint-up(lg) {
					margin: 6px 20px 0;
				}

				&.loading {
					@include loading-spinner(#fff, 20px);
					&::before {
						left: 50%;
						top: 13px;
					}
				}

				svg path,
				svg polygon {
					fill: #474747;
				}

				&::after {
					background: none !important;
				}

				.avyAccount {
                    path,
                    polygon {
                        fill: #003d40;
                    }
                }
			}

			#top-links {
				#account {
					right: 85px;
					@media screen and (min-width:768px) and (max-width:1024px) {
						right: 77px;
					}
					cursor: pointer;
					@include media-breakpoint-up(lg) {
						right: 102px;
					}

					.text {
						display: none;
					}

					svg {
						width: 26px;
						height: 26px;
						margin-right: 0;
						margin-top: 2px;
						display: block!important;
					}

					&.loading {
						svg {
							display: none;
						}
					}

					#user-logged-in {
						.dropdown {
							top: 37px;
							right: -98px;
							left: auto;
							&::before {
								left: 50px;
								@include media-breakpoint-up(md) {
									left: 54px;
								}
								@include media-breakpoint-up(lg) {
									left: 36px;
								}
							}
						}
					}
				}
			}

			#cart {
				right: 44px;
				@media screen and (min-width:768px) and (max-width:1024px) {
					right: 35px;
				}
				padding-right: 10px !important;
				margin-top: 8px !important;

				.number-of-items {
					background: $header-primary;
					position: absolute;
					border: 0;
					color: #ffffff;
					padding: 0px;
					border-radius: 10px;
					top: -9px;
					right: -9px;
					left: auto;
					font-size: 0.8em;
					line-height: 0.4em;
					height: 13px;
					padding: 4px;
				}

				.savings {
					display: none !important;
				}

				svg {
					width: 26px;
					height: 20px;
					float: none;
					margin-top: 0;
				}

				.total {
					display: none !important;
				}

				.dropdown {
					top: 31px;
					right: -44px;

					&:before {
						right: 50px;
						left: auto;
					}
				}
			}
		}
	}

	// push the nav to the top of the page
	#main-navigation {
		@include media-breakpoint-up(md) {
			margin: 0;
			background-clip: border-box;
			padding: 0;
			border-top: 0;

			&::before,
			&::after {
				display: none;
			}

			nav {
				height: 42px;
			}

			.flipper {
				transform: rotateY(180deg);
			}

			.nav-top-level {
				.nav-item a {
					font-size: 0.843em;
					@include media-breakpoint-up(lg) {
						font-size: 0.9em;
						padding: 0px 12px;
					}
				}
				.submenu a {
					font-size: 0.9em;
				}
			}

			ul li.nav-top-level:hover > .submenu {
				top: 43px;
			}
		}

		.checkout-link {
			display: none;
		}
	}

	.desktop-search-panel {
		.open {
			display: block !important;
		}

		&.focus > :not(.open) {
			display: block;
		}

		&.focus .open {
			display: none !important;
		}

		input[type="text"], #algolia-autocomplete {
			display: none;
		}

		.open {
			top: -44px;
			position: absolute;
			height: 43px;
			right: -720px;
			@include media-breakpoint-up(lg) {
				right: -954px;
			}
			background: transparent;
			padding: 6px;
			z-index: 1;
			width: 43px;

			&:hover {
			}

			svg {
				width: 25px;
				height: 25px;
				fill: #fff;
			}
		}
	}
}
