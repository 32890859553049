
#tasting-note-overlay,#print-tasting-note {
    button {
        &:hover {
            text-decoration: none;
        }
    }
    .red {
        h5.modal-title, &.wine-file-quote-section {
            background: #9a0442;
        }
    }
    .white {
        h5.modal-title, &.wine-file-quote-section {
            background: #8fb338;
        }
    }
    .rose {
        h5.modal-title, &.wine-file-quote-section {
            background: #df897c;
        }
    }
    h5.modal-title {
        color: #ffffff;
        font-family: "Crimson Text", Times, serif;
        font-weight: 700;
        font-size: 20px;
        padding: 12px 10px 7px 10px;        
        width: 200px;
        background: #333;
        text-align: center;
        float: none;
        margin: -15px auto 0px auto;
        @media (min-width: 767px){
            left: 38%;
          }
    }
    .flags {
        padding: 10px;
        img {
            max-width: 30px;           
        }
    }
    .modal-lg {        
        @media (min-width: 767px){
            width: 724px;
        }
        @media (min-width: 992px){
            width: 974px;
        }
    }
    .modal-header {
        border-bottom: none;
    }
    .modal-body {
        overflow: hidden;
    }
    h5 {
        font-size: 28px;
        font-weight: 600;
        line-height: 1.06;
        color: #333333;
        margin-bottom: 10px;
        @media (min-width: 767px){
            font-size: 38px;
          }
    }
    .cta-panel {
        margin-bottom: 10px;
        padding: 15px 15px 8px 15px;
        border: 1px solid #ddd;
        li {
            list-style: none;
            margin-bottom: 5px;
            display: block;
            &.favourite-container {
                button {
                    border: none;
                    background: none;
                    padding: 0px;
                    margin: 0px;
                    text-indent: 6px;
                    font-size: 13px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .fa-heart {
                color: #333333;
            }
            .icons {
                width: 25px;
                height: 25px;
                font-size: 1.4em;
            }
            .detail-text {
                font-size: 13px;
                display: inline-block;
                margin-left: 5px;
                a.share:after {
                    font-family: FontAwesome;
                    font-size: 14px;
                    font-weight: 500;
                    content: "\f077";
                    padding-left: 6px;
                    float: right;
                }
                a.share.collapsed:after {
                    font-family: FontAwesome;
                    font-size: 14px;
                    font-weight: 500;
                    content: "\f078";
                    padding-left: 6px;
                    float: right;
                }
            }
        }
        ul.share-links {
            li {
                display: inline-block;
            }
        }
    }
    .buythiswine {
        color: #608822;
        fill: #608822;
        margin-top: 5px;
        padding-top: 7px;
        padding-bottom: 0px;
        font-weight: 700;
        @media (min-width: 767px){
            padding-top: 1px;
        }
    }
    .sharelink-table {
        min-width: 120px;
        margin-top: 10px;
        overflow: hidden;
        height: 35px !important;
        .card {
            ul {
                padding-inline-start: 0px;
                padding-left: 0px;
                border-top: 1px solid #ddd;
                li {
                    list-style: none;
                    margin-left: 4px;
                    margin-right: 2px;
                    margin-bottom: 0px;
                    padding-top: 8px;
                    .js-copy-link svg {
                        width: 16.61px;
                        height: 20px;
                        padding-left: 0px;
                        vertical-align: text-bottom;
                    }
                }
            }
        }
    }
    .wine-file-section {
        padding: 15px 0px 10px 0px;
        min-height: auto;
    }
    .pad-25-top {
        padding-top: 25px;
    }
    .rb-reward-img {
        height: 22px;
        padding-right: 9px;
        width: auto;
    }

    #accolades {
        margin-bottom: 1rem;
        .award-link {
            padding-left: 26px;
            &:before{
                content: url(/images/uk/en/common/icons/award.svg);
                position: absolute;
                left: 0px;
                height: 20px;
                width: 20px;
            }
            &:after {
                font-family: FontAwesome;
                font-size: 14px;
                font-weight: 500;
                content: "\F078";
                padding-left: 6px;
            }
            &.open {
                &:after {
                    content: "\F077";
                }
            }
        }
        .pioneer-award{
            &:before{
                content: url(/images/uk/en/common/icons/pioneer_icon.svg) !important;
            }    
        }
        .award-panel{    
            z-index:1;
            position: absolute;
            padding: 2rem;
            margin: 6px 0;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 #c6c6c6;
            border: solid 0.3px #ccc;
            background-color: #ffffff;
            font-size: 12px;
            width: 100%;
            @media (min-width: 767px){
                width: auto;
            }
            ul {
                padding-left: 10px;
                margin-bottom: 0px;
                li {
                    list-style: none;
                    text-indent: -1em;
                    &:before{
                        display: inline-block;
                        content: '';
                        border-radius: 10px;
                        height: 5px;
                        width: 5px;
                        margin-right: 0.5rem;
                        position: relative;
                        top: -1px;
                        background-color: #c18a26;
                    }                    
                }
            }
        }
    }

    .notes-container {
        padding-top: 0px;
        font-size: 15px;
    }
    hr {
        margin: 2rem 0;
        border-top: none;
        border-bottom: 1px solid #ddd;
        width: 100%;
    }

    .sub-heading {
        font-weight: 600;
        margin-bottom: 1rem;
    }
    .info-container {
        margin-bottom: 5px;
    }
    .info-container > div {
        padding: 0 1rem 1rem 0;
    }

    .web-description, .long-description {
        margin-bottom: 2rem;
    }
    .buythiswine {
        svg {
            width: 25px;
            height: 25px;
            font-size: 1.4em;
            color: #608822;
            Fill: #608822;
        }
    }
	.review-rating {
        font-size: 14px;
        .rating_a {
            display: inline-block;
            font-size: 18px;
        }
        .rating-container {
            margin-bottom: 1rem;
            display: inline-block;
            padding-left: 8px;
            .rating-avg {
                font-weight: 700;
            }
        }
        a {            
            text-decoration: none;
        }
        a.noofreview {
            font-weight: 300;
        }
        a.reviewthiswine {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .review-rating {
        display: inline-block;
        @media (max-width: 767px){
          display: block;
          margin-bottom: 1em;
        }
        &.review-rating-container {
            margin-bottom: 1em;
        }
    }
    .detail-table {
        margin: 11px 0 0;
        padding: 0;
        li {
            list-style: none;
            height: 48px;
            line-height: 1.5;
            margin-bottom: 5px;
        }
        .icons {
            width: 48px;
            height: 48px;
        }
        .detail-text {
            padding: 10px 0;
            margin-left: 50px;
        }
    }

    .cta-panel-mobilelink {
        margin-bottom: 1rem;
    }

    .grid-tasting {
        display: grid;
        grid-gap: 5px; 
        grid-template-columns: 1fr 1fr;
                
        @media (min-width:768px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    
    .wine-file-quote-section {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #333;
        -webkit-print-color-adjust: exact;
        grid-column-start: 1;
        grid-column-end: 4;
        @media (min-width:768px) {
            grid-column-start: auto;
            grid-column-end: auto; 
        }
        .quote-container {
            flex: 1;
            align-self: center;
            color: #fff;
            padding: 1rem;            
            .quote {
                display: block;
                font-family: "Crimson Text", Times, serif;
                font-size: 22px;
                font-style: italic;
                line-height: 1.3;
                text-align: center;
            }
            .person {
                font-family: "Crimson Text", Times, serif;
                font-size: 18px;
                text-align: center;
                font-style: italic;
                display: block;
                margin-top: 5px;
                color: #fff;
            }
        }
    }
    .image-section {
        display: flex;
        .text-center{
            &:nth-of-type(1){
            margin:0 15px;
            }
            .img-thumbnail{
            border:0;
            padding:0;
            height:100%;
            }
        }
    }
    
    .stars-outer {
        display: inline-block;
        position: relative;
		font-family: FontAwesome;
		margin-bottom: 1rem;
      }
       
      .stars-outer::before {
        content: "\f006 \f006 \f006 \f006 \f006";
        color: #c18a26;
      }
       
      .stars-inner {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        width: 0;
      }
       
      .stars-inner::before {
        content: "\f005 \f005 \f005 \f005 \f005";
        color: #c18a26;
      }
    .prod-image-holder-mobile {
        padding-top: 5px;
        padding-bottom: 20px;
    }
    .cta-panel-mobile {
        display: block;
        padding-inline-start: 0px;
        li {
            list-style: none;
            display: inline-block;
            height: 43px;
            width: 29%;
            vertical-align: middle;
            margin: 5px 3px;
            text-align: center;
            &.favourite-container {
                padding: 3px;
                @media (min-width:768px) {
                    padding: 0px;
                }
                button {                    
                    background: none;
                    padding: 0px;
                    margin: 0px;
                    font-size: 13px;
                    border: none;
                    width: 100%;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        @media (min-width:768px) {
                            text-decoration: underline;
                            cursor: pointer;
                        }                        
                    }
                    &.js-remove-favourite::before {
                        font-family: 'FontAwesome';
                        vertical-align: middle;
                        margin: 0 0 -9px;
                        padding-right: 3px;
                        content: "\F004";
                        font-size: 2em;
                    }
                    &.js-add-favourite::before {
                        font-family: FontAwesome;
                        vertical-align: middle;
                        margin: 0 0 -6px;
                        padding-right: 3px;
                        content: "\f08a";
                        font-size: 2em;
                    }
                }
            }
            .icons {
                font-size: 2em;
            }
            .buythiswine {
                padding-top: 10px;
            }
        }
    }
}

#tasting-note-overlay {
    .modal-content {
        border-top: 0;
        .modal-header {
            padding-top: 0;
            position: relative;
            .title {
                float: none;
                text-align: center;
                h3.modal-title {
                    display: inline-block;
                    padding: 10px 24px 7px;
                    background-color: $header-primary;
                    color: #fff;
                }
            }
            .close {
                position: absolute;
                right: 15px;
                top: 15px;
            }
        }
    }
}
#logo {
    text-align: center;
    padding-top: 45px;
    padding-bottom: 19px;
}
.pad-10-top {
    padding-top: 10px;
}
@media (min-width:768px) {
    .wine-file-quote-section {
        display: flex;
        flex: 1;
        padding: 15px;
    }
}
@media (max-width:991px) {
    .tasting-note-overlay {
        padding: 0px;
        margin: 0px;
    }
    .wine-file-quote-section {
        .quote-container {
            flex: 1;
            align-self: center;
            color: #fff;
        }
    }
}
@media (max-width: 767px) {    
    .tasting-note-overlay {
        padding: 0px;
        margin: 0px;
        .wine-file-section {
            min-height: 330px;
        }
        .row.spacer {
            display: inherit;
            .image-section {
                display: flex;
                .text-center{
                    width: 50%;
                &:nth-of-type(1){
                    margin-right: 10px;
                    margin-left: 0;
                }
                .img-thumbnail{
                    width: 100%;
                    max-height: 100% !important;
                    }
                }
            }
        }
        .detail-table li {
            margin-bottom: 5px;
        }
        .wine-file-quote-section {
            padding: 10px;
            margin-bottom: 10px;
            .quote-container {
                padding: 10px;
                margin-bottom: 10px;
            }
        }
    }
    .cta-panel-mobile li {
        .icons {
            padding: 6px 15px;
        }
    }
}
@media (max-width: 375px) and (min-width: 321px) {
    h5.modal-title {
        left: 82px;
    }
}
@media (max-width: 320px) {
    h5.modal-title {
        left: 18.5%;
    }
}
@media (max-width: 991px) and (min-width: 768px) {
    .wine-file-quote-section {
        flex: auto;
        flex-shrink: 2;
    } 
}

.tasting-notes-print-page .tasting-notes-title{
    display: none;
}

#print-tasting-note {
    padding: 20px;
    .review-rating-container , .rightnav-panel{
        display: none;
    }
    h5{
        font-size: 26px;
        font-weight: 500;
    }
    .web-description{
        padding-top: 0;
    }
    .tasting-notes-print-page {
        .tasting-notes-title{
            display: block;
        }
    } 
    #tastingNotes-printSection{
        padding-bottom: 15px;
        .grid-tasting{
            display: flex;
            margin: 20px 10px 5px;
            .tasting-note-image-section{
                 .text-center{
                    margin-left:10px;
                    img{
                        height: 100%;
                    }
                }
            } 
        }
    }
    &.product-tasting-note #logo {
        padding: 0;
    }
    .notes-container{
        a{
            &.emp, &.grapeLink{
                text-decoration: none;
            }
        } 
    }
}

@media print {
    html, body {
       height: 99%;
       page-break-after: avoid !important;
       page-break-before: avoid !important;
    }
    #cloudlink-chat-overlay-buttons,
    #cloudlink-chat-overlay-buttons * {
      display: none !important;
    }
}
