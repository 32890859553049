#main-navigation {
	position: relative;
	box-shadow: 1px 2px 5px -2px rgba(0, 0, 0, 0.3);

	@include media-breakpoint-up(md) {
		margin: 20px 0 0;
		border: 1px solid #e4e4e4;
		border-left: 0;
		border-right: 0px;
		background-clip: content-box;
	}

	&.expanded nav {
		// cancel out the conatiner padding
		left: 0;
		display: block !important;
	}

	nav {
		// set up roboto
		@include font-body(false, $white);
		// mobile styling
		@include media-breakpoint-down(sm) {
			background: $white;
			border-image: linear-gradient(to bottom, #636363, #a0a0a0) 1;
			border-style: solid;
			border-width: 2px;
			border-left: 0;
			border-top: 0;
			left: -100%;
			margin-top: 8px;
			position: absolute;
			transition: left 0.4s;
			width: 85%;
			z-index: 90;
			box-shadow: none;

			& > ul {
				border-bottom: 5px solid #636363;

				&:last-child {
					border-bottom: 0;
				}
			}
		}
		// desktop styling
		@include media-breakpoint-up(md) {
			border: 0;
			padding: 0;
			transition: none;
			line-height: 40px;
			height: 42px;
			padding: 0;
		}
	}

	.container {
		position: relative;
	}

	//IE11 Hack for Store Image
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/* IE10+ CSS styles go here */
		.shop-finder-div{
			margin-left: 35px;
			a{
				outline: none;
			}
		}
   	}
	@media screen and (min-width:768px) and (max-width:1024px) {
		.submenu {
		.shop-finder-img {
			max-width: 205px;
			height: auto;
		}
	  }
	}
	@media screen and (min-width:1024px){
		.submenu {
		.shop-finder-img {
			max-width: 276px;
			height: auto;
		}
	  }
	}
	// all UL's
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			// hide sticky nav by default
			&.sticky-user-icons {
				display: none;
			}

			.menu-ads {
				display: none;
			}

			.social-links {
				ul {
					@include media-breakpoint-up(md) {
						display: flex;
					}
					padding: 0px;
					margin: 0px;
					list-style: none;
					justify-content: center;
					li {
						padding: 10px 0px 10px 0px;
						a {
							svg {
								width: 30px;
								float: left;
								path {
									fill: $gray-700;
								}
							}
							span.visible-xs {
								padding-left: 45px;
							}
							&:hover {
								svg {
									path {
										fill: darken($gray-700, 20%);
									}
								}
							}
						}
						&:last-child{
							a{
								svg{
									width:34px;
									height: 30px;
								}
							}
						}
					}
					img {
						max-height: 34px;
					}
				}
			}

			&.seperator {
				height: 1px;
				margin: 15px 0px!important;
				background: $gray-800;
				max-width: 110px;
			}
			&.sub-title{
				span{
					color: #343a40;
					font-weight: 600 !important;
				}
			}

			@include media-breakpoint-down(sm) {
				position: relative;
				// @todo make this a variable
				border-bottom: 1px solid #999999;
				// hide the border on the last child of the section
				&:last-child,
				&.last-child {
					border-bottom: 0;
				}

				// this is the top clickable area of the nav and contains the icon,
				// name and dropdown link. This has to be seperate so we can bind
				// an event to it.
				.nav-item,
				.login-link {
					padding: 10px 10px 14px 14px;
					color: #666;
					// drop down arrow, we are using CSS because we can
					i {
						border: solid $gray-700;
						border-width: 0 3px 3px 0;
						display: block;
						position: absolute;
						right: 25px;
						top: 0;
						padding: 3px;
						margin-top: 15px;
						transform: rotate(45deg);
						z-index: 1;
					}

					// Wine Rewards Balance
					.balance, .dollar {
						font-weight: bolder;
						color: theme-color("primary");
					}
					.dollar {
						letter-spacing: -1.9px;
					}
				}

				#mobile-loggedin {
					border-style: solid;
					border-color: #666;
					border-left: none;
					border-right: none;
					border-width: 2px;
					border-bottom: none;
				}

				&.nav-top-level > .nav-item,
				&.nav-login-item > .login-link {
					// SVG icon before the menu
					&:before {
						// background: url("/images/uk/en/common/icons/home.svg");
						background-position: 0 0;
						background-repeat: no-repeat;
						// display: inline-block;
						height: 34px;
						margin-right: 8px;
						padding: 0;
						width: 30px;
						content: "";
						float: left;
					}

					// &.nav-wines::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-wine.svg");
					// }
					// &.nav-mixed-cases::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-mixed.svg");
					// }
					// &.nav-wine-clubs::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-wineclubs.svg");
					// }
					// &.nav-event-services::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-events.svg");
					// }
					// &.nav-gifts::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-gifts.svg");
					// }
					// &.nav-advice::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-advice.svg");
					// }
					// &.nav-offers::before {
					// 	background-image: url("/images/uk/en/homepage/icons/menu-offers.svg");
					// }
					&.nav-customer-service::before {
						background-image: url("/images/uk/en/homepage/icons/menu-customerservice.svg");
					}
					&.login-link {
						display: block;
					}
					&.login-link::before,
					&#mobile-loggedin::before {
						background-image: url("/images/uk/en/homepage/icons/account.svg");
					}
					&#mobile-loggedin {
						.rewards-text {
						padding-left: 11px;
						padding-right: 4px;
						}
						.js-wr-balance {
							font-size:14px
						}
					}
					.rewards-text{
						color: #3c3c3c;
					}
					.hidemenu {
						display: none;
					}
				}

				.nav-stores {
				     	padding: 10px 20px;
					&.nav-stores::before {
						background-image: url("/images/uk/en/homepage/icons/menu-store.svg");
						background-position: 0 0;
						background-repeat: no-repeat;
						display: inline-block;
						height: 24px;
						margin-right: 8px;
						padding: 0;
						width: 24px;
						content: "";
						float: left;
					}
				}
				.nav-login-item > .submenu {
					padding-left: 30px;
				}

				// when the item is open, expand the submenu
				&.open-nav {
					// but only the top level
					& > .submenu {
						// second level
						display: block;

						& > ul > li {
							border-bottom: 1px solid #cccccc;
							margin-left: 30px;
							&:last-child {
								border-bottom: 0;
							}

							&.open-nav {
								margin-left: 0;
								& > .nav-item {
									background: #999999;
									padding-left: 50px;
									span {
										color: $white;
									}
								}
								.seperator {
									display: none;
								}

								& > .submenu {
									// third level
									margin-left: 50px;
									margin-right: 20px;

									& > li {
										border-bottom: 0;
										padding: 5px 0;
									}
								}
							}
						}
					}

					& > .nav-item {
						&::before {
							filter: brightness(100);
						}

						background: #666;
						a,
						.rewards-text {
							color: $white;
						}
						.dollar {
							color: $white;
						}
						.balance {
							color: $white;
						}

						i {
							// and flip the arrow around
							transform: rotate(-135deg);
							animation-name: rotateUp;
							animation-duration: 0.2s;
							border-color: $white;
						}
					}

					.nav-item.standalone-nav {
						padding: 0;
						a {
							display: block;
							padding: 10px 10px 14px 14px;
							&:focus {
								outline: unset;
								text-decoration: none;
							}
						}
					}
					.submenu.secondlevel-nav {
						> li {
							padding: 0 !important;
							a {
								display: block;
								padding: 5px 0;
							}
						}
					}
				}
				&.mobilenavChatwithvinny, &.mobilenavBarcodescanner, &.mobilenavStores, &.home-nav-item {
					a {
						display: block;
					}
				}
				// when we close this will add a nice little animation
				&.close-nav {
					i {
						transform: rotate(45deg);
						animation-name: rotateDown;
						animation-duration: 0.2s;
					}
					& > .nav-item {
						.rewards-text {
							color: #3c3c3c;
						}
					}
				}

				.submenu {
					display: none;
				}

				&.telephone {
					a {
						color: #3c3c3c;
						font-size: 1.5em;
						font-weight: 400;
					}
				}
			}

			&.nav-top-level {
				&:hover,
				&.selected {

					a {
						.fa-chevron-right{
							margin-left: 4px;
							font-size: .9em;
						}
					}
				}
			}
			// Tablet
			@include media-breakpoint-up(md) {
				&:first-child .nav-top-level a {
					padding-left: 0 !important;
				}

				&:last-child .nav-top-level a {
					padding-right: 0 !important;
				}

				&.nav-top-level {
					display: inline-block;
					float: left;
					margin: 0;
					border-bottom: 2px solid white;

					.nav-item a {
						display: inline-block;
						//padding: 0px 10px;
						padding: 0px 4px; // Delete after christmas
						font-size: 0.9em;
						font-family: "Roboto",sans-serif;
						text-transform: uppercase;
						@include media-breakpoint-up(lg) {
							padding: 0px 15px;
						}
					}
					.showHideFullLists{
						text-transform: capitalize !important;
					}

					& > .submenu {
						display: none;
						justify-content: space-between;
						flex-wrap: wrap;
						position: absolute;
						left: 0;
						right: 0;
						background-color: #fff;
						transition: 0s ease-in 0.4s;
						z-index: 1;
						margin: 0 auto;
						height: auto;
						border-image: linear-gradient(135deg, #636363, #a0a0a0)
							1;
						border-width: 2px;
						border-style: solid;
						border-top: 0;
						top: 61px;

						// limit the max width to the size of the container
						@include make-container-max-widths();

						& > li {
							// display: flex;
							text-align: left;
							left: 0;

							.submenu {
								display: block;
								padding-right: 20px;
							}
						}

						a {
							font-size: 0.9em;
							&:hover {
								text-decoration: underline;
							}
						}
					}

					// &:hover > .submenu {
					// 	display: block;
					// }

					@media (hover: none) {
						&:hover > .submenu {
							display: none;
						}
					}

					//@include media-breakpoint-down(md) {
						&.selected > .submenu {
							display: block;
						}
					//}

					&:hover,
					&.selected {
						border-color: #636363;

						a {
							background: $white;
							color: $dark;
							text-decoration: none;
						}

						& > .submenu {
							padding: 20px 40px;
							top: 44px;
							@media only screen and (max-width: 1300px) and (orientation : landscape)  { 
								overflow: scroll;
							}

							&::before {
								content: "";
								height: 1px;
								position: absolute;
								left: 0;
								right: 0;
								top: -1px;
								background: #e4e4e4;
							}

							@include media-breakpoint-up(lg) {
								top: 43px;
							}

							.linkmenu,
							.menu-ads {
								display: flex;
							}

							.menu-ads {
								li {
									&:last-child {
										margin-right: 0;
									}
								}
							}

							li {
								flex-grow: 1;
								flex-basis: 16.66667%;
								display: block;
								line-height: 1.2em;
								margin: 10px 10px 10px 0px;
								@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
									flex: 1 1 auto;
								}

								.nav-item {
									color: #494949;
									font-weight: 600;
									&.shop-finder-div {
										.no-pad{
											margin: 0;
										}
									}
								}

								& > .submenu {
									font-size: 0.9em;
								}
							}
						}
					}

					@media (hover: none) {
						&:not(.selected):hover {
							border-bottom-color: #F8F5EF;
							a {
								color: #000000;
							}
						}
					}

					&.offers {
						.nav-offers {
							& > a {
								font-weight: bold;
								// background-color: theme-color("primary");
								// margin: 7px 0;
								// padding: 2px 4px;

								@include media-breakpoint-up(lg) {
									padding: 0px 15px;
								}
							}
						}


					}
					&.gifts{
						.nav-gifts {
							& > a {
								color: #117b53;
								font-weight: bold;
							}
						}
						&:hover {
							border-color: #117b53;
							& > .submenu {

								border-image: linear-gradient(135deg,
								darken(saturate(adjust-hue(#117b53, 6), 2.75), 8.63),#117b53)
							}
						}
					}
					&.christmasnav.offers {
						.nav-offers {
							& > a {
								color: $red;
							}
						}
						&:hover {
							border-color: $red;
							& > .submenu {
								border-image: linear-gradient(
										135deg,
										darken(saturate(adjust-hue($header-secondary, 6), 2.75), 8.63),
										$header-secondary
									)
									1;
							}
						}
					}
					.nav-advice{
						+ .submenu {
							.linkmenu{
								li{
									@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
										flex: 1 1 16.66%;
										&.social-links{
											svg{
													height:30px;
												}
										}
									}
								}
							}
						}
					}

					&.gifts a.red { color:#b12b4b; }

					.hidemenu {
						display: none;
					}
				}
			}

			.nav-home {
				@include media-breakpoint-down(sm) {
					svg {
						display: none;
					}
				}
				@include media-breakpoint-up(md) {
					span {
						display: none;
					}
					svg {
						width: 24px;
						height: 23px;
						margin-top: 9px;
					}
				}
			}

			@include media-breakpoint-down(sm) {
				.back-panel {
					display: none;
				}
			}

			&.nav-top-level {
				&:hover,
				&.selected {
					& > .submenu {
						@media (min-width:1300px) {
							max-height: unset !important;
						}
						@include media-breakpoint-down(sm) {
							max-height: unset !important;
						}
					}
				}
			}

			@include media-breakpoint-up(md) {
				&.flipper-container {
					perspective: 1000px;
					width: 47px;
					z-index: 3;
					height: 30px;

					a {
						padding: 0 !important;
					}

					&:hover {
						border-color: transparent!important;
						a {
							background: none !important;
							path {
								fill: $gray-600;
							}
						}
					}

					.front-panel,
					.back-panel {
						height: 47px;
						width: 47px;
						backface-visibility: hidden;
						-webkit-backface-visibility: hidden;
						position: absolute;
						// top: -24px;
						left: 0;
					}

					.flipper {
						transition: 0.6s;
						transform-style: preserve-3d;
						position: relative;
					}

					.front-panel {
						z-index: 2;
						/* for firefox 31 */
						transform: rotateY(0deg);
						padding: 0 10px !important;
					}

					/* back, initially hidden pane */
					.back-panel {
						transform: rotateY(180deg);

						a {
							margin: 0 10px 0 0;
						}

						.logo {
							background: url(/images/uk/en/homepage/logos/lw-logo-square.svg);
							height: 47px;
							width: 47px;
							display: block;
						}
					}
				}
			}
		}
	}
	// just targeting the top level ul to add the section borders
	& .container > ul {
		margin: 0 -30px;
		padding: 12px 24px;
		// @todo make this a variable
		border-bottom: 4px solid #636363;
		// remove the border from the last item
		&:last-child {
			border-bottom: 0;
		}

		@include media-breakpoint-up(md) {
			margin: 0;
			padding: 0;
			border: 0;
		}
	}

	.tablet-close {
		position: absolute;
		top: 42px;
		right: 15px;
		z-index: 1;
		path {
			fill: $dark;
		}
	}
	.telephone {
		&::before {
			background: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.67 94.47"><defs><style>.cls-1{fill:grey;}</style></defs><title>Asset 2home</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M73.71,94.47A26.14,26.14,0,0,1,65,92.78l-.25-.1C63,91.88,21.34,72.44,1,28.4l-.14-.3L.8,27.77C.61,26.89-1,19,1,14.24l.25-.62L13.76,2.05a7.08,7.08,0,0,1,5.68-2,5.74,5.74,0,0,1,4.14,3.24l12.25,18.2a6.78,6.78,0,0,1-.56,8.59l-4.07,7,0,0c-.38.7-.18,1.46.64,2.19l.12.12L55.15,62.42a2.38,2.38,0,0,0,2.7-.55l.32-.23L63,58.59a7.78,7.78,0,0,1,10.1,0L92,71.64c1.38,1.09,3.1,4.3-.31,8.69l-.12.15L81.27,92.08C80.94,92.41,78.67,94.47,73.71,94.47Zm-6.54-7.29c6.66,2.32,9.45.89,9.91.6L87,76.58a5.5,5.5,0,0,0,.5-.76l-18-12.48c-1.38-1.11-2.3-.42-2.55-.18l-.22.21-5.19,3.26a8.3,8.3,0,0,1-10,.57l-.35-.3L27.79,43.67a7.65,7.65,0,0,1-1.69-9.76l4.26-7.31.19-.22c.67-.76.56-1.17.55-1.17L30.91,25,18.31,6.19l0-.06a2.78,2.78,0,0,0-.41.28L6.33,17.06c-.65,2.4-.22,6.64.28,9.13C25.56,66.94,64.77,86,67.16,87.18Zm9.86.66Z"/></g></g></svg>');
			background-position: 0 0;
			background-repeat: no-repeat;
			display: inline-block;
			height: 24px;
			margin-right: 8px;
			padding: 0;
			width: 24px;
			content: "";
			float: left;
		}
	}
	.telephone {
		margin-top: 9px;
		font-size: 15px;
		padding: 4px 20px;
	}
	.telephone-tab {
		height: 45px;
	}
}

#main-navigation {

	.toplevel-wine .submenu-content a{
		display: block !important;
		text-transform: capitalize !important;
		line-height: 25px;
	}

	.toplevel-wine .submenu-content {
		display: none;
		position: absolute;
		background-color: #f8f5ef;
		box-shadow: 2px 2px 5px hsla(0, 0%, 0%, 0.8);
		width: 11%;
	}

	.toplevel-wine:hover .submenu-content {
		display: block;
	}

	.toplevel-wine .submenu-content a:hover {
		background-color: #ffffff;
	}

	.nav-top-level .toplevel-wine:hover {
		a {
			background: transparent !important;
		}
	}

	.nav-top-level .mobileSubmenu-content {
		display: none !important;
	}

	//Mobile style
	@media (max-width: 575px) {
		.toplevel-wine .submenu-content {
			display: none !important;
		}

		.toplevel-wine:hover .submenu-content {
			display: none !important;
		}

		.nav-top-level .mobileSubmenu-content {
			display: block !important;
			margin-left: 25%
		}
	}
}

@import "./_sticky.scss";

@include media-breakpoint-up(lg) {
	@media (hover: hover) {
		#main-navigation.nav-intended .nav-top-level:hover > .submenu {
			display: block;
		}
	}
}
@include media-breakpoint-up(md) {
	@media (hover: hover) {
		#main-navigation.nav-intended .nav-top-level:hover > .hidemenu {
			display: none;
		}
	}
}

@media (max-width:767px){
	#mobile-loggedin {
		span{
			&.js-wr-balance {
				display:none;
			}
		}
	}
}
ul#showFullLists {
	background: #f8f5ef !important;
	padding: 0 7px 0 3px !important
}