$hero-timing: 0.2s ease-in-out;

.hero {
  transition: all $hero-timing;
  background-color: white;

  @media all and (min-width: 768px) {
    // margin-top: 12px;
  }

  .row {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  .hero-panel {
    background: white;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 11px 0 0;
      height: 320px;
      img {
        height: 310px;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 432px;
      img {
        height: 421px;
      }
    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
      height: auto;
      aspect-ratio: auto 540 / 717;
      img {
        width: 540px;
        max-width: 100%;
        height: auto;
        aspect-ratio: auto 540 / 718;
      }
    }

    a {
      display: block;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        border-radius: 10px;
        margin: 0 10px;
      }

      img {
        object-fit: cover;
        width: 100%;
        position: relative;
      }

      @include media-breakpoint-up(md) {
        // &::after {
        // 	content: "";
        // 	position: absolute;
        // 	top: 0;
        // 	left: 10px;
        // 	bottom: 0;
        // 	right: 10px;
        // 	border-radius: 10px;
        // 	box-shadow: inset 0 0 15px rgba(0,0,0,0.1);
        // }
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.5em 0;

    @include media-breakpoint-down(sm) {
      padding-top: 0.5em !important;
      padding-left: 0.5em !important;
      padding-right: 0.5em !important;
      padding-bottom: 1.5em !important;
      flex-direction: row;
    }
  }

  .tab {
    cursor: pointer;
    flex: 1 !important;
    position: relative;
    margin: 0.5em 0;

    @include media-breakpoint-down(sm) {
      margin: 0 0.25em;
    }

    &::before {
      content: '';
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-width: 13px;
      transform: scale(0);
      transition: $hero-timing;

      @include media-breakpoint-up(md) {
        border-right-color: white;
        left: -36px;
        top: 50%;
        margin-top: -13px;
        transform-origin: center right;
      }

      @include media-breakpoint-down(sm) {
        top: -26px;
        left: 50%;
        border-bottom-color: white;
        margin-left: -13px;
        transform-origin: center bottom;
      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(90deg, rgba(160, 160, 160, 0.25), #a0a0a0);
      transition: 0;
      opacity: 0;
    }

    .content {
      transition: $hero-timing;
      background: rgba(255, 255, 255, 0.8);
      text-align: center;
      color: #3c3c3c;
      min-width: 100%;
      padding: 0.25em;
      display: flex;
      height: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 0.75em 0;
      }

      h3 {
        font-size: 1em;
        margin-bottom: 0;
        font-weight: 600;

        @include media-breakpoint-down(md) {
          font-size: 0.85em;
        }
        @include media-breakpoint-down(sm) {
          font-size: 0.75em;
          letter-spacing: 0.05em;
          padding: 0 5px;
        }
      }

      p {
        font-size: 0.9em;
        margin: 0;
        line-height: 1em;
        @include media-breakpoint-down(md) {
          // hide the p tag on mobile
          display: none;
        }
      }
    }

    &.selected {
      &::before {
        transform: scale(1);
      }

      &::after {
        transform: translate(2px, 2px);
        opacity: 1;
        transition: $hero-timing;
      }

      .content {
        background: white;
        position: relative;
        z-index: 2;
      }
    }
  }
}

.law,
.stw {
  .hero {
    .tab {
      .content {
        h3 {
          @include font-montserrat();
        }
      }
    }
  }
}
