section.quick-links {
  min-height: 50px;
  margin: 40px;

  @include media-breakpoint-down(sm) {
    min-height: 60px;
  }

  @include media-breakpoint-down(xs) {
    min-height: 175px;
  }

  .row {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  a.btn {
    display: block;
    border-width: 2px;
    padding: 10px;
    font-size: 1.2em;
    border-color: $quicklinks;
    color: $quicklinks;
    font-weight: 600;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      border-color: $quicklinks;
      background-color: $quicklinks;
      color: white;
    }

    @include media-breakpoint-only(md) {
      font-size: 1em;
      padding: 5px 10px;
    }

    @include media-breakpoint-down(md) {
      font-size: 1em;
    }

    &.btn-outline-light {
      background-color: theme-color('primary');
    }

    .arrow.right {
      border: inherit;
      border-width: 0 3px 3px 0;
      width: 1px;
      display: inline-block;
      height: 1px;
      position: relative;
      margin-bottom: 1px;
      top: 0;
      padding: 5px;
      vertical-align: middle;
      transform: rotate(-45deg);
      z-index: 1;
      transition: 0.15s;

      @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: 5px;
      }
      @include media-breakpoint-down(xs) {
        position: absolute;
        top: 38%;
        right: calc(50% - 88px);
      }
    }

    &:hover,
    &:focus,
    &:active {
      .arrow.right {
        border-color: $white;
      }
    }
  }

  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    margin: 0 0 30px;
  }
}
