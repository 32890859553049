@mixin loading-spinner($activeColor: "#666666", $width: 40px, $selector: "&::before", $time: 1.5s) {
    // Animation Keyframes
    @keyframes spinner {
        0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }
        100% {
             transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
    }
    // These styles are being applied the element
    // where we include the mixin. I'd recommend for 
    // maintainability to keep these as minimal as possible.
    position: relative;
    
    // Styles to fade out spinner when loaded
    &.-loaded { 
        opacity: 0;
        transition: opacity linear 0.1s;
    }
    
    // Spinner 
    #{$selector} {
        animation: $time linear infinite spinner;
        border: solid 3px rgba(255,255,255,0.3);
        border-bottom-color: #{$activeColor};
        border-radius: 50%;
        content: "";
        height: $width;
        opacity: inherit;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        transform-origin: center;
        width: $width;
        will-change: transform;
    }
}

.loading {
    @include loading-spinner(#666, 26px);
    &::before {
        left: 50%;
    }
}
