.upsell-button-container {
    margin-top: 15px;
}

.signup-button-container {
    padding: 15px;
    .no-thanks {
        margin: 15px 0px;
    }
    .buttons {
        button {
            width: 100%;
        }
        @include media-breakpoint-up(sm) {
            display: flex;
            grid-gap: 5px;
            width: 100%;
            justify-content: end;
            .no-thanks {
                text-align: right;
                margin: 0px;
            }
        }
    }
}

.upsell-button-container {
    a {
        &.claim-link {
            font-size: 1.3rem;
            font-weight: 600;
            .chevron-right {
                color: $green;
            }
        }
    }
}

.upsell-cart-button-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 5px;
    background: #fff;
    padding: 10px;
    align-items: center;
    a {
        &.claim-link {
            font-weight: 600;
            .chevron-right {
                color: $green;
            }
        }
    }
}

#upsellOverlay,
#upsellonly,
#cartUpsell,
#postUpsellWinePlan,
#unlimitedWinePlan,
#postUpsellFTC
{
    .modal-header {
        border-bottom: 1px solid #ADADAD;
        margin: 0 25px;
        padding: 20px 0 15px;
        button.close {
            border: 1px solid #cf004f;
            padding: 0px;
            width: 24px;
        }
        .title {
            width: 95%;
        }
    }
    .modal-body {
        padding: 0;
        .upsell-content {
            padding: 25px;
            h3 {
                line-height: 1.4;
            }
            ul.list-benefits {
                margin-top: 15px;
                margin-bottom: 10px;
                padding-left: 16px;
            }
        }
        label {
            font-weight: normal;
            display: inline;
        }
        .align-center {
            text-align: center;
        }
        
        .sub-header {
            font-weight: 600;
            margin: 10px 0px;
            &.marg-top-25 {
                margin-top: 25px;
            }
        }
        .plan-badge {
            background: #00193d;
            text-align: center;
            padding: 12px 5px;
            color: #fff;
            font-weight: 600;
            max-width: 90px;
            margin: auto;
            font-family: "Oswald", sans-serif;
            font-size: 1.6rem;
        }
        .case-colour-image {
            max-height: 220px;
            margin-top: 35px;
        }
        .upsellonly-button-container {
            button {
                width: 100%;
            }
            .no-thanks {
                margin: 5px 0px;
                @include media-breakpoint-up(sm) {
                    text-align: right;
                    margin-top: 10px;
                }
            }
        }
    }
}
#upsellOverlay {
    .modal-dialog {
        @media (min-width: 767px) {
            //margin: 80px 0 0 ;
            .modal-content {
                position: relative;
                animation: animatebottom 0.4s;
                @keyframes animatebottom {
                    from {
                        bottom: -300px;
                        opacity: 0;
                    }

                    to {
                        bottom: 0;
                        opacity: 1;
                    }
                }
                border: none;
                .modal-title {
                    font-size: 24px;
                    img {
                        margin-bottom: 15px;
                    }
                }
                .modal-header {
                    margin: 0 15px;
                    .title {
                        width: 93%;
                    }
                }
            }
        }
    }
}
#upsellonly {
    .modal-dialog {  
        @media (min-width: 768px) {
            width: 390px;
            margin: 20px;
            float: right;
        }
        @media (min-width: 992px) {
            margin: 200px 20px;
        }
        .modal-header {
            padding: 15px 0 5px;
            border-bottom: 1px solid #cccccc;
            margin: 0 15px 0;
            .title {
                width: 92%;
            }
            button.close {
                width: 22px;
            }
        }
        .modal-title {
            font-size: 22px;
        }
        .modal-body {
            margin: 15px;
            .upsell-content {
                padding: 0px;
            }
            .upsellonly-button-container {
                background: transparent;
                margin: 0;
                padding: 0;
            }
            .plan-badge {
                margin: 0px;
            }
            .signup-button-container {
                padding-bottom: 7px;
            }
        }
    }
}
// style for Unlimited modal
#postUpsellUnlimited, #postUpsellFTC{
    @media (max-width: 767px) {
        .modal-dialog{
            margin: 30px 0 0;
        }
        .modal-content{
            border: none;
            border-radius: 5px 5px 0 0;
            padding: 0;
        }    
    }
    @media (min-width: 992px){
        .modal-lg {
            width: 700px;
        }
    }
    .upsell-content{
        h3{
            font-family: "Roboto",sans-serif;
            line-height: 1.6;
        }
        @media (min-width: 768px) {
            padding: 0;
        }
    }
    .modal-body{
        padding: 15px 15px 0;
        @media (max-width: 767px) {
            padding: 15px;
        }
    }
    .upsell-content{
        label{
            font-weight: normal;
            display: block;
        }
        .total-items{
            margin-top: 30px;
        }
        .link{
            text-decoration: underline;
        }
        .check-box{
            float:left;
            height:50px;
            padding-right:10px;
        }
        .mt-10{
            margin-top: 10px;
        }
        input[type="checkbox"]{
            width: 15px;
            height: 15px;
        }
        .paynow{
            font-weight: 500;;
        }
        
    }
    .info-circle{
        border: 1px solid;
        padding: 7px;
        border-radius: 12px;
        display: block;
        line-height: 0px;
    }
    .fa-lg {
        font-size: 10px;
        line-height: 2px;
        margin: -2px;
    }
    .disclaimer-text{
        font-size: 12px;
        font-weight: bold;
    }
    .text-normal{
        font-weight: normal;
        font-size: 14px;
    }
    .bg-pale{
        background: #f8f5ef;
        height: 50px;
        font-size: 20px;
        padding-top: 12px;
        margin-bottom: 2px;
        .disclaimer-text{
            @media (min-width: 768px) {
                margin: 5px 0;
            }
        }
    }
    .wineList{
        height: auto;
        @media (max-width:960px) {
            height: 90px;
        }
    }
    .unlimited-header{
        color: #7A0040;
        text-transform: none;
        font-size: 20px;
        font-weight: bold;
        padding: 15px 0;
        margin-bottom: 2px;
        @media (min-width: 768px) {
            font-size: 16px;
            padding: 15px 0 0;
        }
    }
    .no-pad-right-desk{
        @media (min-width: 768px) {
            padding-right: 0;
        }  
    }
    .offer-total{
        margin-bottom: 10px;
    }
    .button-container{
        text-align: center;
        .btn{
            margin: 30px 0;
        }
        .buttonpadding{
            padding: 7px;
        }
        a{
            text-decoration: underline;
            @media (min-width: 768px) {
                margin: 40px 0;
            }
        }
        #error-container{
            margin: 15px 0 0;
        }
        @media (min-width: 768px) {
            background: #f8f5ef;
            margin-top: 30px;
            padding: 0 30px;
        }
    }
    .modal-title{
        font-size: 30px;
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    .modal-header{
        margin: 15px;
        padding: 15px 0;
        border-bottom: 1px solid #CCCCCC;
        @media (min-width: 768px) {
            margin: 15px 30px;
            position: relative;
            z-index: 1;
        }
        .close-container{
            right: 0;;
        }
    }
    .upsell-body{
        @media (min-width: 768px) {
            padding: 0 15px;
        }
        .guarantee-delivery-AVY{
            width: 600px;
            margin-top: -120px;
            @media (min-width:768px) and (max-width:991px) {
                width: 500px;
                margin-top: -100px;
            }
        }
    }
    .guarantee-delivery{
        margin: -25px auto 30px;
        padding: 0;
        width: 160px;
        @media (max-width: 767px) {
            width: 200px;
            margin: -30px auto 30px;
        }
    }
    .tooltip {
        position: relative;
        display: inline-block;
        opacity: 1;
        z-index: 1;
        .fa-info-circle {
            cursor: pointer;
        }
        .tooltiptext {
            visibility: hidden;
            width: 200px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            left: 20px;
            opacity: 0;
            transition: opacity 0.3s;
            padding: 10px;
            text-align: left;
            top: -30px;
        }
        &:hover {
            .tooltiptext{
                visibility: visible;
                opacity: 1;
            }
        } 
    }
}
#postUpsellUnlimited {
    .bg-pale{
        .disclaimer-text{
            white-space: nowrap;
        }
    }
}
// style for Fill the case
#postUpsellFTC{
    .modal-body{
        @media (max-width: 767px) {
            margin-bottom: 50px;
            padding: 0 15px 15px;
        }
    }
    .total-items{
        margin-top: 40px;
    }
    .modal-header {
        button.close {
            width: 30px;
            height: 30px;
        }
        border-bottom: none;
        margin: 15px 15px 0;
        .modal-title{
            @media (max-width: 767px) {
                font-size: 20px;
            }
        }
    }
    .button-container{
        @media (min-width: 768px) {
            margin-top: 20px;
        }
    }
    .mt-15{
        margin-top: 15px;
    }
    .title{
        margin-top: 30px;
    }
    .deal-text{
        font-size: 18px;
        font-weight: normal;
        font-family: "Crimson Text", serif;
        @media (max-width: 767px) {
            padding: 0;
        }
    }
    .unlimited-header{
        font-size: 18px;
        padding: 15px 0 0;
        &.pad-15{
            padding: 15px;
            text-align: left;
        }
    }
    .offer-bottle{
        height: 250px;
    }
    .accordion {
        background-color: #f8f5ef;
        color: #444;
        cursor: pointer;
        padding: 10px 15px;
        width: 100%;
        border: none;
        border-bottom:1px solid #cccccc;
        text-align: left;
        outline: none;
        font-size: 15px;
        transition: 0.4s;
    }
      
    .active, .accordion:hover {
        background-color: #f8f5ef; 
    }
    .preferences{
        margin-top: 20px;
        border-bottom: 1px solid #ccc;
        a{
            text-decoration: underline;
        }
        .panel{
            button, .btn-section{
                margin-bottom: 20px;
            }
        }
        .accord-text{
            padding: 15px;
            font-size: 15px;
        }
    }
    .panel {
        &.active{
            display: block;
        }
        padding: 0 18px;
        display: none;
        background-color: #f8f5ef;
        overflow: hidden;
        margin-bottom: 0;
        .wine-header{
            background: #fcfaf7;
            padding: 5px 10px;
            height: 80px;
            p{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0;
                @media (min-width: 767px) {
                    font-size: 13px;
                    font-weight: 500;
                }
            }
            span.stars{
                height: 13px;
                width: 90px;
            }
        }
        .offer-list{
            margin-bottom: 15px;
            &.selected{
                .offer-wine{
                    border: 2px solid #107b53;
                    border-radius: 5px;
                }
                &:nth-child(odd) {
                    padding-right: 7px;
                    margin-bottom: 0;
                }
                &:nth-child(even) { 
                    padding-left: 7px;
                } 
            }
            .offer-wine {
                margin: 15px 0 0;
            }
            .rating-5Star{
                background-position: 0 0;
            }
        }
        .offer-list:nth-child(odd) {
            padding-right: 7.5px;
        }
        .offer-list:nth-child(even) {
            padding-left: 7.5px;
        }
        .white-container{
            background-color: #fff;
            padding: 10px 5px;
            min-height: 136px;
            .offer-bottle{
                height: 120px;
                float: left;
            }
            .wine-details{
                margin-left: 38px;
                font-size: 12px;
            }
            input#qty{
                width: 25px;
                min-height: 25px;
                padding: 0;
                height: 25px;
                display: inline;
            }
            .quantity-section{
                margin-top: 22px;
            }
        }
        
    }
    .selected-items{
        .wine-text{
            font-size: 13px;
            margin: 0;
        }
        display: none;
        &.active{
            display: block;
            background: #ffffff;
        }
        &.only-mobile-view{
            display: none;
            @media (max-width: 767px) {
                display: block;
            }
        }
        &.hidden-xs{
            .total-items{
                margin-top: 20px;
            }
            .bg-pale{
                margin: 0;
            }
            .offer-total{
                margin-top: 2px;
            }
        }
    }
    .circular-div{
        display: inline-block;
        background-color: #9e0c3f;
        color: #ffffff;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        padding: 5px;
        strong{
            font-size: 18px;
        }
    }
    .high-rated{
        margin-top: -10px;
        svg{
            width: 55px;
        }
    }
    .banner-badges{
        display: inline-block;
        vertical-align: middle;
    }
    .mt-10{
        margin-top: 10px;
    }
    .modal-body{
        label{
            vertical-align: text-bottom;
        }
    }
    
}
#UnlimitedLearnMore{
    .modal-dialog{
        @media (max-width: 767px) {
            margin: 10px;
        }
        th{
            white-space: normal;
        }
    }
}
.law{
    #postUpsellUnlimited{
        .modal-content{
            background-color: #EFEAE1;
        }
        .button-container{
            @media (min-width: 768px){
                background: #ffffff;
            }
            .btn{
                border-radius: 5px;
                box-shadow: none;
            }
        }
        .modal-header{
            button{
                color: #000;
                border: none;
            }
        }
        .modal-dialog{
            @media (min-width: 768px){
                width: 655px;
            }
        }
        .bg-pale{
            background: #fff;
        }
        .modal-title, .unlimited-header{
            color: #06193A;
        }
        .unlimited-header{
            font-family: "Crimson Text", serif;
            font-size: 18px;
        }
        .upsell-content{
            h3{
                font-family: 'Noto Serif',sans-serif;
                font-size: 14px;
            }
            input[type=checkbox]{
                width: 16px;
                height: 16px;
            }
            //remove
              input[type='checkbox']:checked:after {
                content: '\2713';
                color:#5E7A2D;
                font-weight: bold;
              }
              input[type=checkbox]:focus{
                  outline: none;
              }
              input[type='checkbox']{
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                appearance:none;
                border: 1px solid #333333;
                box-shadow: none;
                font-size: 1em;
              }
            //till here
        }
        .savings-text{
            color: #D7363B;
        }
        .guarantee-delivery{
            @media (min-width: 768px){
                width: 245px;
                margin: 85px 0 120px;
            }
            @media (max-width: 767px) {
                margin: 45px auto;
            }
        }
    }
}