section.recommended {
  background: $recommended-background;
  color: color-yiq($recommended-background);
  padding: 3em 0;

  &.loading {
    @include loading-spinner(#666, 30px);
  }

  .container {
    position: relative;
    min-height: 400px;
    @media screen and (max-width:350px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  h2 {
    font-size: 2.4rem;
    text-align: center;
    font-weight: 500;
  }

  .window ul,
  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .tabs {
    .tab {
      text-align: center;
      padding: 5px 0;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        background: #fff;
        border: 1px solid #fff;
        color: $recommended-background;
      }

      @include media-breakpoint-up(md) {
        border: 1px solid #999999;
        font-size: 0.8em;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1em;
      }

      &.selected {
        border: 1px solid #fff;
        &:after,
        &:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:before {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #fff;
          border-width: 11px;
          margin-left: -11px;
        }

        // inner color is only for desktop
        @include media-breakpoint-up(md) {
          &:after {
            border-color: rgba(153, 153, 153, 0);
            border-top-color: $recommended-background;
            border-width: 10px;
            margin-left: -10px;
          }
        }
      }
    }
  }

  .view-all {
    text-align: center;
    margin-top: 13px;

    a {
      color: $white;
      text-decoration: none;
      font-weight: 600;
    }
  }

  .window {
    overflow: hidden;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-left: 30px solid $recommended-background;
      border-right: 30px solid $recommended-background;
    }

    ul {
      width: 10000px;
      height: 410px;

      @include media-breakpoint-up(md) {
        transition: transform 0.5s ease-in-out;
      }

      @media screen and (max-width:440px) {
        height: 380px;
      }
      @media screen and (max-width:380px) {
        height: 340px;
      }

      &.animate {
        transition: transform 0.5s ease-in-out;
      }
    }

    li {
      float: left;
      width: 200px;
      text-align: center;
      list-style: none;
      margin: 30px 20px 0 0px;
      padding: 0;
      @include media-breakpoint-up(md) {
        width: 200px !important;
      }
      @media screen and (max-width:350px) {
        margin-right: 8px;
        margin-left: 8px;
      }
      a {
        color: $white;
        text-align:center;

        .name,
        .rating {
          color: color-yiq($recommended-background);
          white-space: nowrap;
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .rating {
          unicode-bidi: bidi-override;
          //direction: rtl;
          height: 30px;
          margin-bottom: 30px;
          display: block;

          @media screen and (max-width:440px){
            width: 170px;
            margin-left: auto;
            margin-right: auto;
            height: 25px;
          }
          @media screen and (max-width:380px){
            width: 140px;
            height: 20px;
          }

          i.fa-star {
            font-size: 24px;
            color: #fff;
            padding: 0 5px;
            @media screen and (max-width:440px){
              font-size: 19px;
            }
            @media screen and (max-width:380px){
              font-size: 17px;
            }
          }
        }

        .image {
          height: 200px;
          border-radius: 112.5px;
          background: radial-gradient(
            ellipse at center,
            rgba(85, 85, 85, 1) 0%,
            rgba(157, 157, 157, 1) 100%
          );
          position: relative;
          margin-bottom: 30px;
          display: block;
          text-align: center;
          @include media-breakpoint-down(sm) {
            display: inline-block;
            width: 200px;
          }

          @media screen and (max-width:440px){
            width: 170px;
            height: 170px;
          }
          @media screen and (max-width:380px){
            width: 140px;
            height: 140px;
          }

          img {
            max-width: 100%;
            height: 240px;
            margin-top: -17.5px;
            position: relative;
            z-index: 2;

            @media screen and (max-width:440px){
              height: 210px;
            }
            @media screen and (max-width:380px){
              height: 180px;
            }
          }

          &::after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            display: block;
            position: absolute;
            top: 0;
            z-index: 1;
            border-radius: 50%;
            opacity: 0;
            transform: scale(1);
            transition: all 0.15s ease-in-out;
            // box-shadow: 0 0 50px 35px white;
          }
        }

        .button {
          display: block;
          border: 1px solid $white;
          padding: 1rem 2rem;
          background: $recommended-cta;
          font-size: 14px;
          margin: 10px;
          transition: all 0.15s ease-in-out;

          @include media-breakpoint-only(md) {
            font-size: 12px;
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;

          .button {
            background: $recommended-cta-hover;
          }

          .image::after {
            opacity: 1;
            transform: scale(1);
            // box-shadow: 0 0 0px 0px transparent;
          }
        }
      }
      @media (max-width: 350px) {
        a.btn-primary {
            padding: 5px;
        }
      }
    }
  }

  // the scroll bars are only visible on desktop
  .scroll {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      border-color: #666;
    }
    @include media-breakpoint-up(md) {
      border: solid $gray-700;
      border-width: 0 5px 5px 0;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      padding: 10px;
      z-index: 1;
      cursor: pointer;
      border-color: #fff;

      &.left {
        left: 0px;
        transform: rotate(-225deg);
      }

      &.right {
        right: 0px;
        transform: rotate(-45deg);
      }
    }
  }
  .arrow.right {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: block;
    position: absolute;
    right: 310px;
    top: 0;
    padding: 3px;
    margin-top: 7px;
    transform: rotate(-45deg);
    z-index: 1;

    @include media-breakpoint-down(md) {
      padding: 3px;
      margin-top: 7px;
      right: 187px;
    }
    @include media-breakpoint-down(sm) {
      padding: 3px;
      margin-top: 7px;
      right: 35px;
    }
    @media (max-width: 320px) {
      right: 16px;
    }
    @media (min-width: 410px) and (max-width: 767px) {
      right: 57px;
    }
  }
}
