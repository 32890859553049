.btn {
	&.loading {
		@include loading-spinner(#FFF, 20px);
		padding-left: 40px;

		&::before {
			left: 20px;
		}
	}
	// &.btn-default {
	// 	background: #333;
	// 	color: $white;
	// }
	// &.btn-primary {
	// 	background: #608916;
	// 	color: $white;
	// }
}