// helper classes
@import '../guarantee/_style.scss';
@import '../content/_icons.scss';

// Reset html font size
html, body {
	@include media-breakpoint-down(sm) {
		overflow: initial;
		overflow-x: hidden;
	}
}

@include media-breakpoint-down(sm) {


	header#new-homepage-test-header,
	header#simple-header {

	border-style: solid;
    border-color: #666;
    border-top: none;
    border-left: none;
	border-right: none;
	border-bottom: none;
	border-width: 1px;
	height: 47px;

	&.expanded {
		height: 40px;
		.logo {
			overflow: hidden;
			z-index: 1;
			path, polygon, rect {
				fill: $red;
			}
	
			@include media-breakpoint-down(sm) {
				a {
					background: url('/images/uk/en/homepage/logos/lw-logo-square.svg');
					height: 40px;
					width: 40px;
					transition: all 50ms ease-in-out;
					transform-origin: 50% 50%;
	
					svg {
						display: none;
					}
				}
				
				position: absolute;
				left: 50%;
				margin-left: -23.5px;
				top: 0;
				z-index: 99;
			}
		}
	  }
	} 
  }

 .header-line{
	width: 100%;
	height: 2px;
	border-bottom: 3px solid lightgray;
	top: 38px;
	left: 0;
	position: absolute;
}
.law{
	header#new-homepage-test-header {
		@include media-breakpoint-up(md) {
			div.logo{
				top: 40px;
			}
		}
	}
}
.hsb{
	header#new-homepage-test-header {
		@include media-breakpoint-up(md){
			div.logo{
				margin-left: -145px;
				a{
					height: auto;
				}
			}
			#main-navigation{
				ul{
					li.nav-top-level{
						.nav-item a{
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}
header#new-homepage-test-header,
header#simple-header  {
	// font-size: 16px;
	background: #FFF;
	position: relative;
	top: 0;
	z-index: 10;
	font-size: 16px;
	padding-top: 0;

	// overrides for BS3
	.row {
		display: flex !important;
    	flex-wrap: wrap !important;
	}

	@include media-breakpoint-up(md) {
		background: #FFF;
	}

	@include media-breakpoint-down(sm) {
		padding: 8px;
	}

	/**
	 * Logo for mobile and desktop
	 */
	div.logo {
		overflow: hidden;
		z-index: 1;
		path, polygon, rect {
			fill: $red;
		}

		@include media-breakpoint-down(sm) {
			a {
				background: url('/images/uk/en/homepage/logos/lw-logo-square.svg');
				height: 57px;
				width: 57px;
				display: block;

				svg {
					display: none;
				}
			}
			
			position: absolute;
			left: 50%;
			margin-left: -26.5px;
			top: 0;
			z-index: 99;
		} 
		// for desktop we want the normal image
		@include media-breakpoint-up(md) {
			a {
				height: 80px;
				width: 182px;
				display: block;
			}
		
			position: absolute;
			top: 24px;
			left: 50%;
			margin-left: -91px;
		}
	}

	// everything else in the masthead (search/account etc)
	@import './masthead/_masthead.scss';
	// cart components
	@import './cart/_cart.scss';
	// menu components
	@import './menu/_menu.scss';
}

header#simple-header {
	div.logo, div.logo a svg {
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.8;
	}
}

@keyframes rotateDown {
	from {
		transform: rotate(-135deg);
	}

	to {
		opacity: rotate(45deg);
	}
}

@keyframes rotateUp {
	from {
		transform: rotate(45deg);
	}

	to {
		opacity: rotate(-135deg);
	}
}

#navigation-overlay {
	background: #636363;
	cursor: pointer;
	position: fixed;
	animation-name: fadeIn;
	animation-duration: 0.8s;
	
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;

	@include media-breakpoint-up(sm) {
		opacity: 0.8;
	}
}

ol.carousel-indicators {
	z-index: 1 !important;
}

header#new-homepage-test-header,
header#simple-header {
	#top-links {
		&.check-btn-enabled #account {
			@media screen and (max-width: 767px) {
				left: 0;
				right: auto;
				margin-left: 0;
				margin-right: 0;
			}
			@media screen and (max-width: 767px) {
				width: 50%;
			}
			@media screen and (max-width: 500px) {
				width: 30%;
			}
			@media screen and (max-width: 380px) {
				width: 25%;
			}
		}
		.header-mob-checkout-btn {
			display: none;
			position: absolute;
			right: 0;
			top: -9px;
			width: 50%;
			@media screen and (min-width: 768px) {
				display: none !important;
			}
			@media screen and (max-width: 500px) {
				width: 70%;
			}
			@media screen and (max-width: 380px) {
				width: 75%;
				top: -4px;
			}
			.btn {
				min-height: auto;
				padding: 5px 10px;
				margin: 0;
				@media screen and (max-width: 500px) {
					margin: 0 0 0 auto;
					width: auto;
				}
				@media screen and (max-width: 380px) {
					padding: 2px 6px;
					font-size: 13px;
				}
			}
		}
	}
}

.stw, .avy, .bbc, .ba {
	header#new-homepage-test-header,
	header#simple-header {
		#top-links {
			.header-mob-checkout-btn {
				top: -5px;
				.btn {
					padding-top: 1px;
					padding-bottom: 1px;
				}
			}
		}
	}
}

body.menu-open {
	overflow: visible !important;
}
header#new-homepage-test-header.is-sticky #main-navigation .nav-top-level .nav-item a{
	@media (min-width: 768px){
	  font-size: 12.9px;
	}
}
.avy header#new-homepage-test-header.is-sticky #main-navigation .nav-top-level .nav-item a{
	@media (min-width: 768px){
	  font-size: 12px;
	}
}
.stw{
	header#new-homepage-test-header ul#showFullLists, header#simple-header ul#showFullLists{
		background: #ffffff !important;
	}
	@media (min-width: 768px){
		header#new-homepage-test-header #main-navigation ul li.nav-top-level .nav-item a{
			font-size: 14px;
		}
		header#new-homepage-test-header.is-sticky #main-navigation .nav-top-level .nav-item a{
			font-size: 13px;
		}
	}
} 