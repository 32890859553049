@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@keyframes fadeOutUp {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.cart-confirmation-popup {
	animation: fadeInDown 0.5s;
	background: white;
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 10px;
	position: absolute;
	right: 0px;
	top: 68px;
	width: 210px;
	z-index: 3;
	white-space: nowrap;

	&.fadeout {
		animation: fadeOutUp 0.5s;
	}

	&::before {
		background: white;
		border: solid #ccc;
		border-width: 0 1px 1px 0;
		content: '';
		display: block;
		left: 175px;
		padding: 7px;
		position: absolute;
		top: -8px;
		transform: rotate(225deg);
		width: 8px;
		z-index: 1;
	}

	@media only screen and (max-width: 767.98px) {
		right: 0 !important;
		&::before {
			left: 175px;
		}
	}

	.checkmark {
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		display: block;
		float: left;
		height: 32px;
		margin: -10px 5px 0 0;
		transform: rotate(45deg);
		width: 32px;
	
		.checkmark_stem {
			background-color: #5f8916;
			height: 13px;
			left: 19px;
			position: absolute;
			top: 9px;
			width: 3px;
		}

		.checkmark_kick {
			background-color: #5f8916;
			height: 3px;
			left: 14px;
			position: absolute;
			top: 22px;
			width: 8px;
		}
	}
}

header.is-sticky .cart-confirmation-popup {
	right: -56px;
	top: 48px;

	&::before {
		left: 127px;
		@media only screen and (max-width: 767.98px) {
			left: 175px;
		}
	}
}
