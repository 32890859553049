@import "_button.scss";
@import "_confirmation-popup";

#cart {
	&.loading {
		.cart {
			display: none;
		}
	}

	@include media-breakpoint-up(md) {
		&:hover .dropdown {
			display: block;
			font-size: 0.9em;
			transition: all 0.3;
		}

		position: relative;
		background: #fff;
		border: 2px solid transparent;
		background-clip: padding-box;
		padding: 5px 5px 15px;
		height: 64px;
		// margin-top: 33px;
		margin-right: 0;

		&::after {
			position: absolute;
			top: -2px;
			left: -2px;
			bottom: -2px;
			right: -2px;
			// background: linear-gradient(to bottom, #fff, #d0d1d1);
			content: "";
			z-index: -1;
		}
	}

	@include media-breakpoint-up(lg) {
        padding: 5px 10px 15px;
    }

	svg {
		@include media-breakpoint-down(sm) {
			width: 31px;
			height: 24px;
		}
		@include media-breakpoint-up(md) {
			width: 30px;
			height: 30px;
			float: left;
			margin-top: 4px;
			margin-left: 0;
		}
		@include media-breakpoint-up(lg) {
			width: 38px;
			height: 38px;
			float: left;
			margin-top: -3px;
			margin-left: 18px;
		}
	}
	.dropdown {
		display: none; // Hide cart preview by default
	}
	// mobile
	@include media-breakpoint-down(sm) {
		text-align: right;
		position: relative;

		.number-of-items {
			background: $header-primary;
			position: absolute;
			color: #ffffff;
			padding: 0px;
			border-radius: 10px;
			top: -7px;
			right: -16px;
			font-size: 0.8em;
			line-height: 1em;
			padding: 0 5px;
		}
	}
	// desktop
	@include media-breakpoint-up(md) {
		position: relative;
		border-bottom-right-radius: 5px;
		text-align: right;

		span.number-of-items {
			background: $header-primary;
			border-radius: 10px;
			min-width: 20px;
			color: $white;
			text-align: center;
			line-height: 18px;
			position: absolute;
			font-size: 0.8em;
			padding: 0 5px;
			margin: 4px 0 0 4px;
    		box-shadow: 0 0 0 1.5px #fff;
    		top: -1px;
    		left: 30px;
    		@include media-breakpoint-up(lg) {
	    		left: 50px;
	    		top: -8px;
			}

			&.added-item {
				animation: bounce 0.2s ease-out 0.5s 6 alternate;
			}
		}

		span.total {
			font-weight: 600;
			font-size: 0.8em;
			line-height: 40px;
			@include media-breakpoint-up(lg) {
				font-size: 1em;
				line-height: normal;
			}
		}

		span.savings {
			color: #c02e35;
			width: 130px;
			font-size: 0.8em;
			font-weight: 400;
			text-align: right;
			position: absolute;
			top: 23px;
			right: 0px;
		}

		.dropdown {
			width: 360px;
			left: auto !important;
			right: -164px;
			text-align: left;
			top: 62px;

			&::before {
				left: 64px;
			}

			table {
				margin-bottom: 20px;
				width: 100%;
				tr {
					border-bottom: 1px solid #dcdcdc;
					&:last-child {
						border-bottom: 0;
					}
				}
				td,
				th {
					padding: 10px 15px;
				}
			}

			.cart-thumbnail {
				text-align: center;
				height: auto;
				max-width: 122px;
				min-width: 50px;
				img {
					max-width: 100%;
					height: auto;
					max-height: 100px;
					&::before {
						content: " ";
						display: block;
						position: absolute;
						max-height: 100px;
						width: 50px;
						background: url("/images/uk/en/law/default_images/default_bottle.png")
							no-repeat left top;
					}
				}
			}

			.cart-window {
				max-height: 300px;
				overflow-y: scroll;
				overflow-x: hidden;
				border-bottom: 1px solid #ccc;
				// a {
				// 	color: theme-color("primary");
				// 	&:hover {
				// 		text-decoration: underline;
				// 	}
				// }
			}

			.actions {
				padding: 15px;

				.col-6:last-child {
					padding-left: 15px;
				}

				.btn-block {
					position: relative;
					text-align: center;
					padding: 0px 45px 0px 30px;
					height: 40px;
					line-height: 38px;
					margin-top: 15px;
					z-index: 1;
					margin-top: 5px;
				}

				.btn-primary {
					position: relative;
					text-align: center;
					padding: 0px 45px 0px 30px;
					height: 40px;
					line-height: 38px;
					margin-top: 15px;
					z-index: 1;
					margin-top: 5px;
				}
				.btn-primary:after {
					content: "";
					border: solid $white;
					border-width: 0 2px 2px 0;
					display: block;
					position: absolute;
					right: 30px;
					top: 0;
					padding: 2px;
					margin-top: 15px;
					transform: rotate(-45deg);
					z-index: 1;
				}
				.btn-primary.minicart-basket:after {
					content: none;
				}
			}
		}

		&:hover {
			.dropdown {
				display: block;
			}
		}

		&::after {
			left: 0;
			border-bottom-right-radius: 5px;
		}
	}

	@media (min-width: 768px) and (max-width:991px) {
		.dropdown {
			right: -134px;
			&::before {
				left: 134px;
			}
		}
	}

	.guarantee {
		border-top: 1px solid #dcdcdc;
		background: url("/images/uk/en/homepage/guarantee.png") no-repeat 15px center;
		color: #666666;
		padding: 30px 15px 30px 130px;
		font-size: 0.9em;
		line-height: 1.2em;

		h4 {
			font-size: 0.9em;
			font-weight: bold;
			@include font-body();
		}
	}
}
