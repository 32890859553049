.offer-listing-grid {
    .template {
        margin: 15px 0px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); /* see notes below */
        grid-gap: 1em;
        align-items: end;
        > div {
            text-align: center;
        }
        .atb, .savings-block {
            margin-top: 15px;
        }
        .image, .name, .contents-link {
            margin-bottom: 15px;
        }
        .image {
            img {
                max-height: 240px;
            }
        }
        .savings-block {
            .savings-text {
                font-size: 1.8rem
            }
        }
        .case-price {
            font-weight: 600;
            font-size: 2rem;
        }
    }
}

.offer-bulk-deals {
    .deal-header {
        text-align: center;
    }
    .deal-container {
        display: grid;
        margin: 15px 0px;
        grid-gap: 15px;
        grid-template-columns: 1fr;
        border-bottom: 1px solid #ddd;
        &:last-child {
            border: none;
        }
        @include media-breakpoint-up(sm) { 
            grid-template-columns: 1fr 240px; 
        }
        .fa-chevron-right:before{
            color: #117b53;
        }
        &.two-case {
            img {
                max-width: 275px;
                @media (min-width:768px) and (max-width:992px){
                    max-width: 232px;
                }
                @media (max-width: 767px){
                    max-width: 240px;
                }
            }
        }
        &.three-case{
            img{
                @media (min-width:768px) and (max-width:992px){
                    max-width: 155px;
                }
                @media (max-width: 767px){
                    max-width: 240px;
                }
            }
        }
    }
    .image-container {
        text-align: center;
        // display: flex;
        // flex-wrap: wrap;
        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr;
        @include media-breakpoint-up(sm) { 
            grid-template-columns: auto auto auto; 
        }
        img {
            max-width: 210px;
        }
    }
    .button-container {
        margin: auto;
        width: 100%;
        .contents-link{
            margin: 10px 0;
        }
        .offerPrice{
            font-size: 16px;
        }
    }
}
#christmas-case-contents, #case-contents{
    .selection-container .deals{
        &.active, &:hover{
            background-color: #CF004f;
            border-color: #CF004f;
            color: #FFFFFF;
            cursor: pointer;
        }
        @include media-breakpoint-up(sm) {
            border-radius: 5px 5px 0 0;
        }
    }
   
    .modal-dialog{
        @include media-breakpoint-up(sm) {
            width: 738px;
            .pick-white-section{
                padding: 0 15px;
            }
        }
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }
    .modal-header{
        margin: 15px;
        @include media-breakpoint-down(sm) {
            margin: 15px;
            text-align: center;
        }
        padding: 15px 0;
        border-bottom: 1px solid #000
    }
    .loading::before {
        top: 10%;
    }
    .nav-tabs {
        @include media-breakpoint-up(sm) {
            border-bottom: 1px solid #ddd;
        }
        li{
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
                padding: 0!important;
            }
            a{margin-right: 0;}
        }
    }
}