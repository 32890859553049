section.recently-viewed {
	padding: 2em 0;
	position: relative;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	margin-bottom: 2em;

	@supports (clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px)) {
		&::before {
			content: "";
			position: absolute;
			top: -11px;
			left: 0;
			right: 0;
			height: 50px;
			background: linear-gradient(to bottom, rgba(221, 221, 221, 0) 0%, #dddddd 50%, #dddddd 100%);
			border-bottom-left-radius: 100% 100%;
			border-bottom-right-radius: 100% 100%;
			border-top-left-radius: 100% 100%;
			border-top-right-radius: 100% 100%;
			clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px);
		}

		&::after {
			content: "";
			position: absolute;
			bottom: -11px;
			left: 0;
			right: 0;
			height: 50px;
			background: linear-gradient(to top, rgba(221, 221, 221, 0) 0%, #dddddd 50%, #dddddd 100%);
			border-bottom-left-radius: 100% 100%;
			border-bottom-right-radius: 100% 100%;
			border-top-left-radius: 100% 100%;
			border-top-right-radius: 100% 100%;
			clip-path: polygon(0 40px, 100% 40px, 100% 100%, 0 100%);
		}
	}

	&.loading {
		@include loading-spinner(#666, 30px);
	}

	.container {
		position: relative;
		min-height: 200px;
		@include media-breakpoint-only(md) {
			max-width: 712px;
		}
	}

	h3 {
		text-align: center;
		margin-bottom: 10px;
		font-weight: 500;
	}

	.window ul, .tabs {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.window {

		overflow: hidden;
		position: relative;
		width: 100%;
		border-left: 40px solid $white;
		border-right: 40px solid $white;

		ul {
			width: 10000px;
			// height: 150px;

			@include media-breakpoint-up(md) {
				transition: transform .2s ease-in-out;
			}

			&.animate {
				transition: transform .2s ease-in-out;
			}
		}

		li {
			float: left;
			width: 100px;
			text-align: center;
			list-style: none;
			margin: 15px 20px 0 0px;
			padding: 0;
			@include media-breakpoint-only(md) {
				margin: 15px 20px 0 0px;
			}

			a {		
				.image {
					// width: 200px;
					border-radius: 112.5px;
					position: relative;
					display: block;

					img {
						max-height: 100px;
						max-width: 100px;
					}

					img[alt] {
						font-size: 13px;
					} 
				}

				.button {
					display: block;
					border: 1px solid $white;
					padding: 1rem 2rem;
					background: theme-color("primary");
					font-size: 14px;
					margin-top: 10px;
				}

				&:hover {
					text-decoration: none;

					.button {
						background: $white;
						color: $dark;
						transition: all 0.15s ease-in-out;
					}
				}
			}
		}
	}

	// the scroll bars are only visible on desktop
	.scroll {
		@include media-breakpoint-up(md) {
			
			border: solid $gray-700;
			border-width: 0 5px 5px 0;
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -5px;
			padding: 10px;
			z-index: 1;
			cursor: pointer;
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}

			&.left {
				left: 0px;
				transform: rotate(-225deg);
			}

			&.right {
				right: 0px;
				transform: rotate(-45deg);
			}
			
		}
	}
}