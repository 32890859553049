section.newsletter {
	padding: 0 0 3em 0;
	text-align: center;
	position: relative;
	border-bottom: 1px solid #dddddd;
	line-height: 5rem;

	.row {
		display: flex !important;
		flex-wrap: wrap !important;
	}

	h2 {
		@include font-heading(2em);
	}

	p {
		@include media-breakpoint-down(sm) {
			line-height: 1.5em;
			margin-bottom: 1.5rem;
		}
	}

	@supports (clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px)) {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			height: 50px;
			background: linear-gradient(to bottom, rgba(221, 221, 221, 0) 0%, #dddddd 50%, #dddddd 100%);
			border-bottom-left-radius: 100% 100%;
			border-bottom-right-radius: 100% 100%;
			border-top-left-radius: 100% 100%;
			border-top-right-radius: 100% 100%;
			clip-path: polygon(0 0, 100% 0%, 100% 10px, 0 10px);
			bottom: -40px;
		}
	}

	input[type="email"] {
		width: 100% !important;
		font-style: italic;
		font-weight: 300;
	}
	[type="submit"] {
		@include media-breakpoint-up(md) {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	@include media-breakpoint-down(sm) {
        [type="submit"] {
			width: 100%;
			flex:1;
			margin-top: 15px;
			max-width: none;
		}

		input[type="email"] {
			text-align: center;
		}

		.form-inline {
			flex-direction: column;
			
			.form-group {
				max-width: none;
				padding: 0;
			}
		}
	}

	.form-group {
		margin-bottom: 0;
		.ErrorField{
			border-color: #d7363b!important;
			color: #d7363b!important;
			background-color: rgba(215,54,59,.1)!important;
		}
		.ValidationErrors{
			padding: 0;
			margin: 0;
			width: 100%;
			color: #d7363b;
			background: transparent;
			text-align: left;
			border: 0;
			&:before{
				content: "i";
				display: inline-block;
				width: 2rem;
				height: 2rem;
				background: #d7363b;
				border-radius: 50%;
				color: #fff;
				display: -webkit-inline-box;
				display: -ms-inline-flexbox;
				display: inline-flex;
				position: relative;
				top:-2px;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				font-size: 13px;
				font-weight: 100;
				margin-right: 1rem;
			}
		}
	}
}