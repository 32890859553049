// account and cart share the same dropdown
#account, #cart {
	.dropdown {
		display: none;
		position: absolute;
		min-width: 160px;
		z-index: 3;
		border: 1px solid #ccc;
		border-radius: 3px;
		background-color: $white;

		.submenu {
			padding: 10px 15px;
			list-style: none;
			margin-bottom: 0px;

			li {
				padding: 5px 0px;
				&.sign-out {
					border-top: 1px solid $gray-500;
					padding-top: 10px;
					margin-top: 5px;
				}
				hr {
					margin: 0px;
				}

				a:hover {
					text-decoration: underline;
				}
			}
		}

		p {
			padding: 15px;
			margin-bottom: 0;
		}

	    &::before {
			content: '';
			border: solid #ccc;
			border-width: 0 1px 1px 0;
			display: block;
			position: absolute;
			top: -6px;
			padding: 5px;
			transform: rotate(225deg);
			z-index: 1;	
			width: 10px;
			background: $white;
		}
	}
}