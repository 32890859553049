.notify-confirmation {
    margin-top: 1rem;
}

.notify-button-container {
    margin-top: 1rem;

    .form-check {
        border: 1px solid #911440;
        color: #911440;
        padding: 1rem;
        .form-check-input {
            position: absolute;
            margin-top: .3rem;
        }
        .form-check-label {
            font-weight: 400;
            margin-left: 18px;
            display: inline-block;
        }
    }
}