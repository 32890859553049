.cell-container {
  @include media-breakpoint-down(sm) {
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.cell-content {
  @include media-breakpoint-down(sm) {
    position: absolute !important;
    top: 0;
  }

  &--empty {
    background: $empty-cell-grey;
  }
}

.cell-button {
  position: absolute;
  bottom: 20px;
}