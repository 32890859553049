// @import './sass/_variables.scss';
@import './sass/mixins/_animations.scss';
@import './sass/mixins/_btn.scss';
@import './sass/_typography.scss';

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header, footer, .bootstrap-4 {
	// @import "~dw-uk-bootstrap/scss/_reboot.scss";
	@import "~dw-uk-bootstrap/scss/_images.scss";
	@import "~dw-uk-bootstrap/scss/_modal.scss";
	@import "~dw-uk-bootstrap/scss/_grid.scss";
	@import "~dw-uk-bootstrap/scss/_nav.scss";
	@import "~dw-uk-bootstrap/scss/_forms.scss";
	@import "~dw-uk-bootstrap/scss/_input-group.scss";
	@import "~dw-uk-bootstrap/scss/_utilities.scss";
	.row::before, .row::after {
		content: normal;
	}
}

.bootstrap-4 {
	background: $white;
	font-size: 16px;
	.form-control {
		height: calc(2.25em + 2px);
	}
}

@import './components/password-reset/_style.scss';
@import './sass/_variation_testing.scss';
@import './sass/_loaders.scss';
@import './components/header/_style.scss';
@import './components/footer/_style.scss';
@import './components/delivery-options/style';
@import './components/button/_style.scss';
@import './components/hero/_style.scss';
@import './components/modal/_style.scss';
@import './components/quick-links/_style.scss';
@import './components/recommended/_style.scss';
@import './components/content/_style.scss';
@import './components/parallax/_style.scss';
@import './components/newsletter/_style.scss';
@import './components/recently-viewed/_style.scss';
@import './components/search/_style.scss';
@import './components/tasting-note/_style.scss';
@import './components/hsbc/_style.scss';
@import './components/stock-notify-optin/_style.scss';
@import './components/mixed-case-highlights/_style.scss';
@import './components/case-tabbed-offer/style.scss';
@import './components/partnership-content/_style.scss';
@import './components/upsell/_style.scss';
@import './components/offers/_style.scss';
@import './components/tetris/_style.scss';
@import './components/payment/_payment-logos.scss';
@import './sass/_christmas.scss';
@import './sass/_bugfixes.scss';
