section.parallax {
  position: relative;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 5rem 0;

  .background,
  .foreground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .background {
    background: url('/images/uk/en/homepage/content/parallax.png') no-repeat;
    background-size: cover;
  }

  .foreground {
    background: url('/images/uk/en/homepage/content/parallax-window.png');
    background-size: cover;
  }

  h2 {
    @include font-heading(1.5rem);
    text-align: center;
    margin-bottom: 2rem;
  }
}

#bottom-parallax {
  display: flex;
  min-height: 340px;

  @include media-breakpoint-down(md) {
    min-height: 280px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 645px;
  }

  @include media-breakpoint-down(xs) {
    min-height: 380px;
  }
}

section.giftbanner {
  img {
    object-fit: contain;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    color: #fff;
    width: 970px;
    height: auto;
    aspect-ratio: auto 970 / 360;
    @media screen and (min-width: 768px) and (max-width: 992px) {
      width: 768px;
      aspect-ratio: auto 768 / 320;
    }
    @media screen and (max-width: 767px) {
      width: 640px;
      aspect-ratio: auto 640 / 800;
    }
  }
}

@each $key, $val in $color-options {
  .giftbanner.#{$key} {
    background-color: #{$val};
  }
}

// @each $key, $val in $contentful-colors {
//   .giftbanner.contentful-#{$key} {
//     background-color: #{$val};
//   }
// }