/**
 * special case to add an animation effect to the add to basket button
 */
button.js-add-to-cart.btn-loading {
	@include loading-spinner(#fff, 20px);

	padding-left: 20px;

	&::before {
		left: 15px;
	}
}

.btn {
	min-width: 0;
}