/**
 * Existing site fixes, please remove when we have fully implement the header!
 */

.modal-header {
	overflow: hidden;
}
// sale style primary banner buttons override
.salestyle .btn-primary {font-weight: normal !important; border: 0 !important;}
// safari fix for oddities associated with having both bootstrap3 and 4 on a page
.container.masthead > .row::before { content: normal; }
.container.masthead > .row::after { content: normal; }
// fix for the wine plan next and previous buttons UXE-3686
#flexSearch #prevPage, #flexSearch #nextPage {
	color: #333 !important;
}

.bootstrap-4 {
	background: #FFF;
}

.big-strip {
	z-index: 10;
	position: relative;
}