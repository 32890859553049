#top-links {
	// these are smaller than the rest of the header
	font-size: 14px;
	font-weight: 500;
	text-align: right;
	width: 100%;
	padding: 5px 0;

	a:hover {
		text-decoration: underline;
		display: inline-block;
	}

	#account, .help-link {
		float: right;
	}

	#account, .stores-link {
		float: right;
	}

	.help-link {
		a {
			border-left: 1px solid #000;
			padding: 0 0 0 5px;
			line-height: 14px;
			display: inline-block;
			margin-left: 5px;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.stores-link {
		a {
			border-left: 1px solid #000;
			padding: 0 0 0 5px;
			margin-left: 5px;
			line-height: 14px;
			display: inline-block;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	#account {

		svg {
			&.account-link {
				circle.cls-1 {
					fill: #333333;
				}
				.cls-1 {
					fill: #333333;
				}
			}
		}
		
		@include media-breakpoint-down(sm) {
			position: absolute;
			left:0;
			float:none;

			.text {
				display: none;
			}

			svg {
				width: 26px; height: 26px;
				margin-right: 0;
				margin-top: 2px;
				display: block!important;
				&.account-link {
					height: 26px;
					width: 26px;
				}
			}

			&.loading {
				svg {
					display: none;
				}
			}
		}

		.js-wr-balance {
			.balance, .dollar {
				font-weight: bold;
				color: $header-secondary;
			}
			&.text {
				a {
					border-left: 1px solid #000;
					padding: 0 0 0 5px;
					margin-left: 5px;
					line-height: 14px;
					display: inline-block;
				}
			}
		}

		// right: 95px;

		// 		.text {
		// 			display: none;
		// 		}

		// 		svg {
		// 			width: 26px; height: 26px;
		// 			margin-right: 0;
		// 			margin-top: 2px;
		// 			display: block !important;
		// 		}

		// 		&.loading {
		// 			svg {
		// 				display: none;
		// 			}
		// 		}


		// 		#user-logged-in {
		// 			.dropdown {
		// 				top: 37px;
		// 				right: -98px;
		// 				left: auto;
		// 				&::before {
		// 					left: 50px;
		// 					@include media-breakpoint-up(md) { 
		// 						left: 54px;
		// 					}
		// 					@include media-breakpoint-up(lg) { 
		// 						left: 36px;
		// 					}
		// 				}
		// 			}
				//}
		

		#user-not-logged-in {
			&.loading::before {
				height: 16px;
				width: 16px;
				left: 10px;
			}
		}

		#user-not-logged-in, #user-logged-in {
			padding-bottom: 10px;
		}

		#user-logged-in:hover, #user-logged-in.hover {
			position: relative;
			.dropdown {
				text-align: left;
				margin-left: -35px;
				display: block;
				top: 25px;
				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
				&::before {
					left: 15px;
				}

				@include media-breakpoint-down(md) {
					margin-left: -47px;
					&::before {
						left: 75px;
					}
				}

				.tablet-close {
					position: absolute;
					top: 5px;
					right: 10px;
					svg {
						height: 14px !important;
						width: 14px !important;
					}
				}
			}
		}
	}
	@media (min-width:768px) and (max-width:991px) {
		display: flex;
		flex-direction: column-reverse;
	}
}
.hsb #top-links{
	margin: 45px 0 0;
	text-align: left;
}
/*
#account {

	


			#user-not-logged-in {
				span.link:after {
					display: none;
				}
				.dropdown {
					display: none;
				}
			}

			a.login, a.logged-in {
				span.link {
					display: none;
					@include media-breakpoint-up(md) {
						display: block;
						padding-left: 38px;
					}
				}
				&:hover {
					text-decoration: none;
				}
			}
			a.logged-in {
				span.status {
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}
			@include media-breakpoint-up(md) { 
				&:hover {
					.dropdown {
						display: block;
					}
				}
			}
		
		    a {
		    	&:hover {
					text-decoration: underline;
				}
		    }
			// @include media-breakpoint-down(sm) { text-align: center; }

			@include media-breakpoint-up(md) {
				border-right: 0;
				border-bottom-left-radius: 5px;

				&::before {
					content: '';
					position: absolute;
					right: 0;
					top: 9px;
					height: 40px;
					width: 1px;
					// background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(204,204,204,1) 50%,rgba(255,255,255,0) 100%);
				}

				&::after {
					right: 0;
					border-bottom-left-radius: 5px;
				}

				a {
					color: #666;
					font-weight: 300;
				}
			}
		}
		// cart has a mobile and desktop view
		#user-logged-in {
			display: none;
		}
		#user-logged-in  {
			a {
				line-height: 16px;
				min-width: 100px;
				@include media-breakpoint-up(md) {
					font-size: 0.9em;
				}
				@include media-breakpoint-up(lg) {
					font-size: 1em;
					.link {
						// padding-top: 8px;
					}
				}
				.name {
					color: #333;
					font-weight: 600;
					word-break: break-word;
					display: table-cell;
					padding-bottom: 2px;
					vertical-align: middle;
				}
				
				.account-text {
					display: none;
					@include media-breakpoint-up(lg) {
						display: block;
					    color: #666;
					    width: 130px;
					    font-size: 0.8em;
					    font-weight: 300;
					    // position: absolute;
					    // top: 38px;
					}
				}
				.status {
					display: block;
				}
			}
		}	

		#user-not-logged-in  {
			a {
				@include media-breakpoint-up(md) {
					line-height: 42px;
				}
			}
		}
	}
*/