$e-voucher-heading-colour: #333333;
$e-voucher-font-colour: #333333;
$e-voucher-offset-white: #F6F6F6;
$e-voucher-green: #3D8122;
$e-voucher-button-ft-colour: white; 
$e-voucher-gold-dark: #A28F54;
$e-voucher-gold-light: #CBB864;
$e-voucher-border-colour: #979797;
$e-voucher-error: #D7363B;
$e-voucher-font-family-sec: "Crimson Text", serif;

.e-vouchers{
    .desktop-evoucher{
        .fake-card-gold{
            width: 100%;
            padding-top: 63%;

            img{
                max-width: 150px;
                left: calc(50% - 75px);
                top: calc(50% - 53.75px);
            }

            p{
                display: block;
                font-size: 3rem;
                right: 1rem;
                border: 1rem;
            }
        }
        // @include media-breakpoint-up(lg){   
        //     padding-top: 6rem;

        //     .fake-card-gold{
        //         width: calc(100% - 5rem);
        //         padding-top: 55%;
        //     }
        // }
    }

    .form{ 
        .fake-card-gold{
            width: 100%;
            padding-top: 65%;

            img{
                max-width: 150px !important;
                left: calc(50% - 75px) !important;
                top: calc(50% - 53.75px) !important;
            }

            p{
                display: block;
                font-size: 2rem;
                right: 1rem;
                border: 1rem;
            }

            @include media-breakpoint-up(sm){            
                width: 350px;
                padding-top: 50%;
                margin: 0 auto;

                p{
                    font-size: 3rem;
                }
            }
        }

        .voucher-form-heading{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 2rem;
 
            @include media-breakpoint-up(md){
                font-size: 20px;
                margin-bottom: 1.5rem;
            }
        }

        form{
            .gift-amount, .about-details{
                .inner-form-wrapper{
                    background-color: white;
                    padding: 1.5rem;
                    float: left;
                    width: 100%;
                    label{
                        display: block;
                        clear: both;
                    }
                }

                .char-count{                    
                    margin-top: 0.5rem;
                    font-size: 14px;
                    text-align: right;
                    width: 55px;
                    float: right;
                    padding-top: 5px;
                }

                .voucher-message{
                    margin-bottom: 0;
                    float: left;
                    width: 100%;
                }

                @include media-breakpoint-up(md){
                    background-color: white;

                    .inner-form-wrapper{
                        padding: 0;
                        background-color: transparent;
                    }
                }
            }
            .gift-amount{
                .form-check{
                    float: left;
                    width: 33.333333%;
                    padding-left: 0;

                    &:nth-child(1){
                        padding-right: 10px;
                    }

                    &:nth-child(2){
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    &:nth-child(3){
                        padding-left: 10px;
                    }

                    input{
                        display: none;

                        &:checked ~ {

                            label{
                                border-color: $e-voucher-green;
                                background-color: $e-voucher-green;
                                color: white;
                            }
                        }

                        &.input-error ~ {
                            label{
                                //Important tags to override any hover/focus states
                                border-color: $e-voucher-error ;
                                color: $e-voucher-error;
                                background-color: rgba($e-voucher-error, 0.1);
                            }
                        }
                    }

                    label{
                        display: block;
                        width: 100%;
                        padding: 1.25rem 1rem;
                        text-align: center;
                        border: 1px solid $e-voucher-border-colour;
                        color: $e-voucher-green;
                        cursor: pointer;

                        &:hover,&:focus{                            
                            border-color: $e-voucher-green;
                        }
                    }
                }

                .form-group{
                    float: left;
                    width: 100%;
                    margin-top: 15px;
                    input{
                        height: 49px;                        
                        -webkit-appearance: none;
                        -moz-appearance: textfield !important;

                        &:hover,&:focus{                            
                            border-color: $e-voucher-green;
                        }
                    }
                }

                // @include media-breakpoint-up(lg){

                //     .form-check{
                //         width: calc(33.33333% - 67px);
                //     }

                //     .form-group{
                //         width: 200px;
                //         margin-top: 0;
                //         margin-bottom: 0;
                //         padding-left: 15px;
                //     }
                // }
            }

            @include media-breakpoint-up(md){
                .gift-amount{
                    padding: 4rem 2rem;
                }

                .about-details{
                    padding: 0 2rem 3rem 2rem;
                }

            }
            @include media-breakpoint-down(sm){     
                .about-details{
                    .voucher-form-heading{
                        margin-top:2rem;
                    }
                }
            }
            .fake-card{
                margin: 1.5rem 0;
            }

            input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .submit-section{
        @include media-breakpoint-up(sm){
            padding-left: 0;
            padding-right: 0;
        }   
    }
}

.e-vouchers-preview{
    .preview-title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(md){
            font-size: 20px;
        }

        span.back{
            margin-left: 2rem;
            font-size: 16px;
            font-weight: normal;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
    .e-voucher-preview-section{
        background-color: transparent;
        padding: 0;
        @include media-breakpoint-up(md){
            background-color: white;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    .recipient-email{
        margin-bottom: 2rem;
        @include media-breakpoint-up(md){
            margin-bottom: 0;
        }
    }

    .email-preview{
        .wrapper{
            border: 1px solid black;
            background-color: white;

            .title{
                padding: 2rem;
                border-bottom: 1px solid black;

                p{
                    font-weight: bold;
                    padding: 0;
                    margin: 0;
                    color: $e-voucher-font-colour;
                }
            }

            .content{
                padding: 2rem;

                .js-message{
                    word-break: break-all;
                }
            }
        }

        .fake-card-gold-preview{
            background-image: linear-gradient($e-voucher-gold-dark, $e-voucher-gold-light);
            height: 0;
            overflow: hidden;
            width: 100%;
            max-width: 100%;
            padding-top: 60%;
            border-radius: 7px;
            position: relative;
            margin: 2rem auto 0 auto;

            p.for-name{
                position: absolute;
                top: 2rem;
                left: 2rem;
                color: white;
                max-width: calc(100% - 110px);
                font-size: 15px;
            }

            p.price{
                position: absolute;
                top: 1.75rem;
                right: 2rem;
                color: white;
                font-size: 25px;
            }

            img{                
                position: absolute;
                right: 2rem;
                bottom: 2rem;
                width: 40px;
            }

            .e-voucher-info{                
                position: absolute;
                left: 2rem;
                bottom: 1.75rem;
                color: white;
                font-size: 13px;
                p{
                    margin-bottom: 0.25rem;
                    color: white;

                    a{
                        //Incase a device thinks the card number is a phone number...
                        color: white;
                    }

                    &.expires{
                        margin-bottom: 0;
                        font-size: 12px;
                    }
                }
            }

            @include media-breakpoint-up(sm){
                width: 290px;
                padding-top: 190px;
                border-radius: 12px;

                p.price{
                    font-size: 30px;
                }

                img{
                    width: 55px;
                }

                .e-voucher-info{
                    font-size: 13px;
                    p{
                        &.expires{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .submit-section{
        @include media-breakpoint-up(md){
            padding-right: 0;
            padding-left: 0;
        }
    }
}