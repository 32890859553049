.search-background {
	position: fixed;
	top: 0; left: 0; bottom: 0; right: 0;
	background: rgba(0,0,0,0.8);
	z-index: 99;

	@include media-breakpoint-down(sm) {
		top: 45px;
	}
}

.desktop-search-panel {

	
	// the default colour of the menu is white
	color: #3c3c3c;
	position: absolute;
	margin: 45px 0 0;

	
	   #algolia-autocomplete{
		.aa-InputWrapperPrefix{
		   button.aa-SubmitButton{
				appearance: none;
				background: none !important;
				border: 0;
				margin: 0;
		   }
		}
	   }

		@include media-breakpoint-down(sm) {
			background: #FFF;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			padding: 15px;
			z-index: 100
		}
		.old-search {
		// style the input box
		input[type="text"] {
			font-style: italic;
			margin-right: 40px;

			// @include media-breakpoint-down(sm) {
			// 	padding: 10px 15px;
			// 	font-size: 1.1em;
			// 	width: calc(100% - 30px);
			// }

			// @include media-breakpoint-up(md) {
			// 	line-height: 1em;
			// 	height: 36px;
			// 	padding: 18px 18px;
			// 	font-size: 0.85em;
			// 	width: 100%;
			// }
		}

		
		// style the button
		button[type="submit"] {
			position: absolute;
			background: #454545;
			border: 2px solid #a3a3a3;
			padding: 0;
			
			@include media-breakpoint-down(sm) {
				right: 15px;
				top: 15px;
				height: 44px;
				width: 44px;
			}

			@include media-breakpoint-up(md) {
				
					right: 0px;
					top: 0px;
					height: 44px;
					width: 44px;
				
			}

			// the icon inside the button
			svg {
				// path { fill: #FFF !important; }
				// different height and width for mobile/main
				@include media-breakpoint-down(sm) { height: 25px; margin-top: 5px; }
				@include media-breakpoint-up(md) { height: 25px; }
			}
		}
	}

	// hide everything but the autocomplete
	@include media-breakpoint-down(sm) {
		.qpf {
			display: none;
		}
	}
	// hide everything, we will be able to see everything when it's focused
	@include media-breakpoint-up(md) {
		.close, p, .autosuggest, .qpf {
			display: none;
		}
	}

	.search-group-container {
		position: relative;
		&.disappear {
			visibility: hidden;
			height: 0px;
		}
	}
	&.focus {

		padding: 40px;
		right: 0;
		left: 0;
		margin: 0 auto 0 auto;
		z-index: 998;

		// make the input box bigger
		input[type="text"] {
			padding: 10px 20px;
			font-size: 20px;
			width: 100%;
			margin-bottom: 10px;
			line-height: 1em;
			height: auto;
			@include media-breakpoint-down(sm) {
				padding: 10px 90px 10px 10px;
			}
		}
		// green background for the search
		.old-search {
		button[type="submit"] {
			background: $header-primary;
			top: 1px;
			right: 0px;

			@include media-breakpoint-up(lg) {
				//right: 26px;
			}

			// white search icon
			svg {
				height: 30px;
				width: 30px;
				margin-top: 4px;
				path { fill: #FFF; }
			}

			&:hover  {
				background: lighten($header-primary, 10%);
			}
		}
	

		button[class="close"] {
			border: 1px solid #ccc;
			padding: 5px 8px;
			top: 10px;
			right: 10px;
			color: #666666;
		}
		}

		@include media-breakpoint-down(md) {
			padding: 40px 40px 40px 40px !important;
			margin-top: 45px;
		}

		// these are mostly desktop styling
		@include media-breakpoint-up(md) {
			background: #FFF;
			top: 25%;
			// make everything visible again
			.close, p, .autosuggest, .qpf {
				display: block;
			}
		}		
	}

	.close {
		position: absolute;
		top: 20px;
		right: 30px;
	}

	ul.autosuggest {

		list-style: none;
		margin: 0;
		padding: 0;
		border: 2px solid #d8d8d8;
		min-height: 50px;
		
		position: absolute;
		background: #FFF;

		border-top: 0;
		top: 115px;
		width: calc(100% - 80px);
		@-moz-document url-prefix() {
			top: 111px;
		}
		@media (min-width: 320px) and (max-width: 358px) {
			top: 133px;
			@-moz-document url-prefix() {
				top: 130px !important;
			}
		}
		@media (max-width: 320px){
			max-height: 360px !important;
			overflow: scroll;
		}
		@include media-breakpoint-down(sm) {
			max-height: 420px;
			overflow: scroll;
		}
		@media (min-width: 370px) and (max-width: 870px) and (orientation:landscape) {
				max-height: 120px;
				overflow: scroll;
		}
		@media (max-width: 767px) and (orientation:landscape){
			max-height: 210px;
		}
		@media (min-width: 320px) and (max-width: 590px) and (orientation:landscape) {
			max-height: 150px;
		}
		li {
			a {
				display: block;
				padding: 10px 20px;
				border-top: 0;
				border-bottom: 1px solid #d8d8d8;
				text-decoration: none;
				color: #3c3c3c;
				font-size: 1.2em;

				&:hover{
					background: $header-primary;
					color: $white;
				}
			}

			&:last-child a {
				border-bottom: none;
			}

			&.selected {
				a {
					background: $header-primary;
					color: $white;
				}
			}
		}
	}

	.qpf {
		border-top: 2px solid #d8d8d8;
		padding: 20px 0 0 0;
		h2 {
			@include font-heading(1.5em);
			text-align: center;
		}

		&.loading {
			@include loading-spinner(#666, 30px);

			&::before {
				margin-left: 50%;
			}
		}

		p {
			text-align: center;
			margin-top: 10px;

			a {
				display: inline-block;
				padding: 10px 100px;
			}
		}

		ul.facets {

			display: flex;
			padding: 0;
			margin: 20px 0 20px 0;
			min-height: 40px;

			
			& > li {
				list-style: none;
				position: relative;
				justify-content: space-between;
				flex-wrap: wrap;
				flex-grow: 1;
				margin: 0 10px;
				padding: 0;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				h3 {
					border: 1px solid #d4e3e9;
					background: #267490;
					color: #FFF;
					padding: 10px;
					font-size: 1em;
					word-wrap: nowrap;
					cursor: pointer;
					margin-bottom: 0;
				}



				& > ul {
					display: none;
				}

				&.show > ul {
					display: block;
					position: absolute;
					border: 1px solid #d8d8d8;
					border-top: 0;
					background: #FFF;
					top: 40px;
					left: 0px;
					right: 0;
					margin: 0;
					padding: 0;

					li {
						display: block;
						padding: 10px 20px;
						border-top: 0;
						border-bottom: 0;
						font-style: italic;
						text-decoration: none;
						color: #3c3c3c;
						cursor: pointer;

						&:hover {
							background: #d4e3e9;
						}
					}
				}
			}
		}

		.selected-facets {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				border: 1px solid #d8d8d8;
				padding: 5px 10px;
				font-style: italic;
				display: inline-block;
				margin-right: 10px;
				cursor: pointer;
			}
		}
	}
	.clear-search{
		position: absolute;		
		color: #ccc;
		cursor: pointer;
		top: 14px;
		right: 60px;
		@include media-breakpoint-up(sm) {
			font-size: 20px;
			top: 10px;
			right: 65px;
		}
	}
}
