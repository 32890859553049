.payment-logos {
	img {
		margin-right: 10px;
		margin-bottom: 10px;
		@media (max-width: 767px) {
			width: 55px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media (max-width: 767px) {
		img.gpay{
			width:72px;
		}
	}
}
