$e-voucher-heading-colour: #333333;
$e-voucher-font-colour: #333333;
$e-voucher-offset-white: #F6F6F6;
$e-voucher-green: #3D8122;
$e-voucher-button-ft-colour: white; 
$e-voucher-gold-dark: #A28F54;
$e-voucher-gold-light: #CBB864;
$e-voucher-border-colour: #979797;
$e-voucher-error: #D7363B;
$e-voucher-font-family-sec: "Crimson Text", serif;

//Global markup start
.padded-large{
    padding-top: 3rem; //1rem to offset the logo
    padding-bottom: 2rem;
    @include media-breakpoint-up(md){
        padding-top: 4rem; //1rem to offset the logo
        padding-bottom: 3rem;
    }
    // @include media-breakpoint-up(lg){
    //     padding-top: 5rem;
    //     padding-bottom: 5rem;
    // }
}

.padded-large-top{    
    padding-top: 3rem; //1rem to offset the logo
    @include media-breakpoint-up(md){
        padding-top: 4rem; //1rem to offset the logo
    }
    // @include media-breakpoint-up(lg){
    //     padding-top: 5rem;
    // }
}

.padded-large-bottom{
    padding-bottom: 2rem;
    @include media-breakpoint-up(md){
        padding-bottom: 3rem;
    }
    // @include media-breakpoint-up(lg){
    //     padding-bottom: 5rem;
    // }
}

.title-section{
    margin-bottom: 2rem;
    .section-title{
        font-size: 38px;
        font-weight: bold;
        font-family: $e-voucher-font-family-sec;
        color: #333333;
        @include media-breakpoint-up(md){
            font-size: 45px;
        }
    }
    .intro-text{
        color: $e-voucher-font-colour;
    }

    .voucher-type{
        color: $e-voucher-font-colour;
        font-weight: bold;
        margin-top: 2rem;
        font-size: 18px;
        @include media-breakpoint-up(sm){
            font-size: 20px;
        }
    }
}

.form-error{
    float: left;
    width: 100%;
    color: $e-voucher-error;
    font-size: 14px;
    display: none;
    padding-top: 1rem;
    width: calc(100% - 55px);
    padding-right: 1rem;
    &::before{        
        content: "i";
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background: $e-voucher-error;
        border-radius: 50%;
        color: white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 100;
        margin-right: 1rem;
    }
}

.fake-card-gold{    
    background-image: linear-gradient($e-voucher-gold-dark, $e-voucher-gold-light);
    height: 0;
    overflow: hidden;
    width: 75px;
    padding-top: 55px;
    border-radius: 7px;
    position: relative;

    img{
        position: absolute;
        width: 100%;
        top: calc(50% - 20px) !important;
        left: calc(50% - 35px) !important;
        max-width: 70px !important;
    }

    p{
        display: none;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        margin: 0 !important;
        color: white !important;
        font-size: 2rem;
    }

    @include media-breakpoint-up(sm){
        width: 47.5%;
        padding-top: 33%;
        border-radius: 12px;
        img{                        
            top: calc(50% - 21px) !important;
            left: calc(50% - 37.5px) !important;
            max-width: 75px !important;
        }
    }

    @include media-breakpoint-up(md){
        img{
            top: calc(50% - 26px) !important;
            left: calc(50% - 45px) !important;
            max-width: 90px !important;
        }
    }

    // @include media-breakpoint-up(lg){
    //     img{
    //         top: calc(50% - 35px) !important;
    //         left: calc(50% - 50px) !important;
    //         max-width: 100px !important;
    //     }
    //     p{
    //         display: block;
    //     }
    // }
}

.gift-cards, .e-vouchers, .e-vouchers-preview{
    background-color: $e-voucher-offset-white;

    .submit-section{
        margin-top: 2rem;
        
        .pre-submit-text{
            float: left;
            width: 100%;
            justify-content: flex-end;
            margin: 0 0 1rem 0;
        }
    }

    .voucher-back-button, .voucher-next-button{
        padding: 1.5rem 1rem;
        border: 1px solid $e-voucher-green;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        display: block;
        width: 100%;

        &[disabled]{
            opacity: 0.25;
            cursor: not-allowed;
        }
    }
    
    .voucher-back-button{
        background-color: transparent;
        color: $e-voucher-green;
    }
    
    .voucher-next-button{
        background-color: $e-voucher-green;
        color: white;
    }

    form{
        label{
            font-weight: normal;
        }

        .form-control{
            border: 1px solid $e-voucher-border-colour;
            border-radius: 0px;
            &.input-error{
                //Important tags to override any hover/focus states
                border-color: $e-voucher-error !important;
                color: $e-voucher-error !important;
                background-color: rgba($e-voucher-error, 0.1) !important;
                &:placeholder{
                    color: $e-voucher-error !important;
                }
            }
        }
    }
}

.error-block {
    background-color: rgba($e-voucher-error, 0.2);
    border: 1px solid $e-voucher-error;
    padding: 2rem 1.5rem;
    display: none;
    p {
        padding: 0;
        margin: 0;
    }
}
//Global markup end
//Gift-card start
.voucher-section{
    .voucher{
        height: 0;
        overflow: hidden;
        padding-top: calc(100% + 15px);
        background-color: white;
        border: 1px solid $e-voucher-border-colour; //Replace
        cursor: pointer;
        position: relative;
        box-shadow: 0px 0px 0px 1px transparent;
        transition: ease .3s border, ease .3s box-shadow;

        @include media-breakpoint-up(sm){
            padding-top: 65%;
        }

        .inner{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 1rem;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p{
            margin-bottom: 1rem;
            color: $e-voucher-font-colour;
        }

        &.giftcard{
            img{
                width: 75px;
                height: 50px;
                @include media-breakpoint-up(sm){
                    width: 125px;
                    height: 70px;
                }
                @include media-breakpoint-up(md){
                    width: 150px;
                    height: 100px;
                }
                // @include media-breakpoint-up(lg){
                //     width: 205px;
                //     height: 141px;
                // }
            }
        }

        &.active{
            border: 1px solid $e-voucher-green;
            box-shadow: 0px 0px 0px 1px $e-voucher-green;
        }
    }
}

//Gift-card end

.bbc{
    .title-section{
        .section-title{
            font-family: "Roboto",sans-serif;
            font-weight: 500;
        }
    }
}
