.overlay {
	background: rgba(0,0,0,0.8);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
}

#upsellonly {
	&.overlay {
		background: none;
	}
}

// Fixed the misaligned close buttons on modals UXE-3484
.modal-header {
	display: block;
	.close-container {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}