section#password-reset {

    #steps {

		li {
			display: block;	
			list-style-type: none;	
			width: 50%;
			float: left;
			position: relative;
			text-align: center;
		   
		}

		li.step-1:before {
			content: '1';
		}

		li.step-2:before {
			content: '2';
		}

		li:before {
			width: 55px;
			height: 55px;
			line-height: 54px;
			display: block;	
			border-radius: 50%;
			margin: 0 auto 10px auto;
			border: 1px solid $gray-900;
			text-align: center;
			background-color: $white;
			z-index: 5;
			position: relative;
		}

		li:after {
			content: '';
			width: 100%;
			height: 1px;
			background-color: $gray-900;
			position: absolute;
			left: -56%;
			top: 27px;
			z-index: 1; 
		}

		li:first-child:after {
			content: none; 
		}

		li.active {
			color: $header-primary;
			font-weight: 800;
		}

		li.error {
			color: #D7363B;
			font-weight: 800;
		}

		li.active:before{
			border-color: $header-primary;
			border-width: 2px;
		}

		li.error:before{
			border-color: #D7363B;
		}
		
		p {
			font-family: $font-family-base;
			@include media-breakpoint-up(sm){
				font-size: 20px;
			}	
		}	
	}
	
	h1 {
		font-family: $font-family-base;
		font-size: 20px;
	}

    p {
		font-family: $font-family-base;
		font-size: 14px;
	}	

	a {
		text-decoration: underline;
	}

}

section#password-reset form {

	input[type="text"],
	input[type="password"] {
		border: 1px #ccc solid;
		border-radius: 0;
		width: 100% !important;
		font-weight: 300; 
		box-shadow: none;
		color: #555555;

		&:focus{
			box-shadow: none;
		}
	}

	button { 
		background-color: $header-primary;
		border-color: $header-primary;

		&:hover, &:focus {
			background: darken(saturate(adjust-hue($header-primary, -4), 15.21), 9.61) !important;
			border-color: darken(saturate(adjust-hue($header-primary, -4), 15.21), 9.61) !important;
		}
	}

	#emailAddress.form-control,
	#tempPassword.form-control {
		height: calc(2.25em + 8px) !important;
	}

	input[type="text"].is-invalid,
	input[type="text"].is-valid,
	input[type="password"].is-invalid,
	input[type="password"].is-valid {
		background-image: none;
	}

	input[type="text"].is-invalid,
	input[type="password"].is-invalid {
		border-color: #d7363b!important;
		color: #d7363b!important;
		background-color: rgba(215,54,59,.1)!important;
	}

	.invalid-feedback {
		color: #D7363B;
		
		&:before{
			content: "i";
			display: inline-block;
			width: 1.75rem;
			height: 1.75rem;
			background: #d7363b;
			border-radius: 50%;
			color: #fff;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			position: relative;
			top: 0px;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			font-size: 13px;
			font-weight: 100;
			margin-right: .5rem;
		}
	}
}

#passwordAdvice {

	.modal-dialog {

		@include media-breakpoint-down(sm){
			width: 100% !important;
			max-width: none !important;
			margin: 0px !important;
			transform: translateY(25vh);
			height: 75vh;
			max-height: 75vh;
			transition: all 1s;
		}

	}

	.modal-content {

		border-radius: 10px;

		@include media-breakpoint-up(sm){
			border-radius: 5px;
		}

		.modal-header {
			
			width: 100%;
			margin: 0px;
			padding: 15px;
			border: none;

			@include media-breakpoint-down(sm){
				margin-top: 30px;
				padding: 15px 15px 0 15px;

			}
		
			h5.modal-title {

				color: $black;
				font-family: inherit;
				font-size: 24px;
				font-weight: 500;
				width: calc(100% - 32px);
				height: 48px;

				@include media-breakpoint-up(sm){
					height: 64px;
					padding: 15px;
					margin: 0 16px 0 16px; 
					border-bottom-color: $gray-500;
					border-bottom-width: 1px;
					border-bottom-style: solid;
					padding-bottom: 1.5rems;
				}
				
			}

			button {
				display: block;
				position: absolute;
				top: 20px;
				right: 20px;
				padding: 1rem !important;
				opacity: 1;

				@include media-breakpoint-down(sm){
					width: calc(100% - 30px) !important;
					top: 20px;
				}

				span {
					visibility: hidden;
					position: absolute;
					top: 0px;
					right: 0px;
					height: 1px;
					padding: none !important;
				}

				span:before , span:after{ 
					visibility: visible;
					position: absolute;
					top: 0px;
					display: block;
                    content: ' ';
					background-color: $black; 
					height: 13px;
					width: 3px;
				}

				span:before {
					z-index: 100;
					transform: rotate(135deg);
				}
				span:after {
					z-index: 101;
					transform: rotate(45deg);
				}

			}

			button span:hover {
				opacity: 0.5 ;
			}
		}


		.modal-header::before {

			display: block;
			content: ' ';
			position: absolute;
			top: 30px;
			left: calc(50% - 26.5px);
			background-color: #ccc;
			height: 3px;
			width: 50px;

			@include media-breakpoint-up(sm){
				display: none;
			}

		}

		.modal-body {
			margin: 0;
			padding: 15px 15px 30px 15px;
			font-family: $font-family-base;
			font-size: 16px;
			font-weight: 300;

			@include media-breakpoint-up(sm){
				font-weight: 300;
				line-height: 1.45;
				margin: 0 30px;
				padding: 25px 15px;
			}

			.sub-header {
				font-weight: 500;
				margin: 25px 0 5px 0;
			}

			ul {
				margin-left: -20px;

				@include media-breakpoint-up(sm){
					list-style-position: outside;
					margin-left: -40px;

					li::marker {
						font-size: 2rem;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md){

	.hide-mobile {
		display: none !important;
	}

}