.case-container {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        > div {
            flex: 0 50%;
            /*demo*/
            box-sizing: border-box;
            padding: 1rem;
        }
    }

    .item {
        width: 100%;
        img {
            max-height: 210px;
        }
        .image {
            padding-right: 20px;
        }
        .detail {
            h4 {
                font-size: 26px;
                font-weight: 600;
            }

            .detail-table {
                margin: 10px 0;
                padding: 0;
                li {
                    list-style: none;
                    height: 35px;
                    display: flex;
                    align-items: center;

                    .icons {
                        svg {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .text {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

#case-contents {
    @media (min-width: 992px){
        .modal-lg {
            width: 992px;
        }
    }
    .modal-header {
        border: none;
        h5 {
            font-size: 20px;
            @include media-breakpoint-up(md) {
                font-size: 32px;
            }
        }
    }
    .modal-body{
        padding: 0 20px 20px;
    }
    .give-away-container{
        text-align: center;
        margin-top: 50px;
        .mt-15{
            margin-top: 15px;
        }
        h2{
            color: #CF004F;
            font-family: 'Oswald', sans-serif;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 40px;
        }
        .give-aways-info{
            margin-top: 50px;
        }
        .info-container{
            height: 300px;
            position: relative;
            background-size: 100%;
            p{
                text-transform: uppercase;
                color: #ffffff;
                font-size: 18px;
                font-family: 'Oswald', sans-serif;
                font-weight: 700;
                padding: 20px;
                margin: 0;
            }
        }
        .green-bg{
            background: #0c400c;
            height: 100px;
        }
        .info-2-col{
            @media (max-width: 767px){
                padding: 0;
                margin-top: 15px;
            }
        }
        .info-2{
            background: url('https://uat.laithwaites.co.uk/images/uk/en/law/wineplans/29-sep-2021/image4.png') center center no-repeat;
            background-size: cover;
            background-position: top;
            .green-bg{
                background: #0c400c;
                position: absolute;
                width: 100%;
                bottom: 0;
            }
        }
        .btn-primary{
            margin-top: 50px;
        }
    }
    .selection-container {
        text-align: center;
        h2{
            color: #CF004F;
            font-family: 'Oswald', sans-serif;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 40px;
        }
        .sub-heading{
            text-transform: uppercase;
        }
        @include media-breakpoint-up(md) {
            justify-content: space-evenly;
            margin-bottom: 10px;
            align-items: center;
		}

        #case-webheadline {
            margin-bottom: 10px;
            @include media-breakpoint-up(md) {
                flex-grow: 2;
            }
        }
    }

    select#case-options {
        // box-shadow: inset #f8f8f8 0px 1px 2px 0px, inset rgb(0 0 0 / 50%) 0px 1px 3px 0px;
        height: 44px;
        -webkit-appearance: none;
        background-image: url("/assets/icons/chevron.svg");
        width: 100%;
        display: block;
        border-width: 1px;
        border-style: solid;
        border-color: #727272;
        border-image: initial;
        padding: 0 36px 0px 10px;
        border-radius: 0px;
        background-repeat: no-repeat;
        background-position: calc(100% - 0.75rem) center;
        display: block;
        margin-bottom: 10px;
    }
}

.case-tabbed-offer {
    margin: 20px 0px;
    
    .nav {
        @include media-breakpoint-up(md) {
			display: flex;
            justify-content: center;
            border-bottom: 0px;            
		}
    }
    .case {        
        @include media-breakpoint-up(md) {
			display: flex;
            justify-content: center;
		}
        > div {
            flex: 0 50%;
            /*demo*/
            box-sizing: border-box;
            padding: 1rem;
        }
        .image {
            margin: 16px 30px 10px;
            background: #eaaf1d;
            background-image: linear-gradient(to bottom, #eaaf1d, darken(#eaaf1d, 10%));
            position: relative;
            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
            height: 180px;

            &.red {
                background: #b82326;
                background-image: linear-gradient(to bottom, #b82326, darken(#b82326, 20%));
            }

            &.white {
                background: #afd254;
                background-image: linear-gradient(to bottom, #afd254, darken(#afd254, 20%));
            }

            &.rose {
                background: #f9cd91;
                background-image: linear-gradient(to bottom, #f9cd91, darken(#f9cd91, 20%));
            }
            .roundal {
                background: $primary;
                padding: 18px;
                color: $white;
                border-radius: 50%;
                width: 104px;
                text-align: center;
                line-height: 20px;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 14px;
                .just {
                    font-weight: 600;
                    display: block;
                    font-size: 18px;
                }
                .price {
                    font-weight: 600;
                    display: block;
                    font-size: 26px;
                    margin: 4px 0px;
                }
                .bottle {
                    display: block;
                }
            }
        }
        .detail {
            padding-left: 20px;

            a.emp {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }

            h3 {
                font-size: 26px;
                font-weight: 500;
                margin-bottom: 13px;
            }

            p {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 15px;
            }

            .price {
                font-size: 16px;
                .bold {
                    font-weight: 600;
                }
            }
            .saving {
                font-size: 13px;
            }

            .terms-link {
                margin-top: 10px;
                font-size: 1.2rem;
            }
        }
    }

    .nav-item {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: auto;
        }
        .nav-link {
            cursor: pointer;
            padding: 10px 20px;
            margin: 0px 8px;
            background: $white;
            
            color: #000037;
            border: solid 2px #000037;
            min-width: 220px;
            text-align: center;
            font-family: "Montserrat", San-Serif;
            font-size: 16px;
            font-weight: 600;
            border-radius: 0px;
            @include media-breakpoint-up(md) {
                display: inline-block;
            }
            &.active {
                background: #000037;
                border-color: #000037;
                color: $white;
                &:after {
                    top: 100%;
                    left: 45%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    border-width: 12px;
                    border-top-color: #000037;
                }
            }
        }
    }

    .tab-pane {
        border: 0px;
        padding: 0px;
        margin-top: 20px;
    }
}
