.masthead {
	
	#algolia-autocomplete{
		.aa-InputWrapperPrefix{
			button.aa-SubmitButton{
				appearance: none;
				background: none !important;
				border: 0;
				margin: 0;
			}
		}
		.aa-SubmitIcon{
			path{
				fill: #000000;
			}
		}
	}

	position: relative;

	@include media-breakpoint-up(md) {
		height: 110px;
	}

	svg path,
	svg polygon {
		// white for mobile
		@include media-breakpoint-down(sm) {
			fill: #333333;
		}
		// grey for desktop
		@include media-breakpoint-up(md) {
			fill: #474747;
		}
	}

	// menu is only visible on mobile
	#menu {
		svg#menu-hamburger {
			width: 27px;
			height: 27px;
		}
		svg#menu-close {
			width: 17px;
			height: 17px;
		}
		// on mobile we switch between the open and closed icon
		@include media-breakpoint-down(sm) {
			#menu-close {
				margin-top: 5px;
				display: none;
			}

			&.open {
				#menu-close {
					display: block;
				}
				#menu-hamburger {
					display: none;
				}
			}

			/**
				 * @todo add an animation effect between the open and close styles
				 */
		}
	}
	// search as a header item is only available in mobile, its in the nav
	// for desktop
	#search {
		svg {
			width: 26px;
			height: 26px;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	@import "_top-links.scss";
	@import "_dropdown.scss";

	#account,
	#cart {
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: 0px;
			right: 0;
			z-index: 1;
			width: auto;
			height: 40px;
			padding: 0;
			background: none;
			text-align: left;
			margin: 6px 10px 0;
		}

		@include media-breakpoint-down(sm) {
			&:hover {
				.dropdown {
					// hide on mobile
					display: none !important;
				}
			}
		}
	}

	#account {
		@include media-breakpoint-down(sm) {
			right: 70px;
			top: -8px;
		}
		@include media-breakpoint-down(xs) {
			right: 55px;
			top: -8px;
		}
	}

	@include media-breakpoint-down(sm) {
		.loading {
			position: absolute !important;
			margin-right: 1.5rem !important;
		}
	}
	#cart {
		@include media-breakpoint-down(sm) {
			top: -4px;
    		position: relative;

			.number-of-items {
				padding: 2px 6px;
				position: relative;
    			top: -42px;
				right: -20px;
			}
		}
	}
	.header-right {
        @include media-breakpoint-down(sm) { 
            display: flex;
            justify-content: space-between;
            padding: 0;
        }
        @media (max-width: 991px) and (min-width: 768px) {
            padding-left: 0px;
        }
    }
	#checkout-link {
		padding-right: 0;
	}

	.checkout-link {
		position: relative;
		text-align: center;
		margin-top: 15px;
		z-index: 1;
		margin-top: 5px;
		text-transform: none;
		min-width: 0;
		width: 100%;
		&.disabled {
			background-color: #C9C9C9;
			color: #6F6F6F;
			border-color: #C9C9C9;
		}
	}

	.checkout-link:hover {
		transition: none !important;
	}
	.checkout-link:after {
		content: "";
		border: solid #fff;
		border-width: 0 2px 2px 0;
		display: inline-block;
		position: relative;
		margin-left: 5px;
		top: -1px;
		padding: 2px;
		transform: rotate(-45deg);
		z-index: 1;
	}	
}
