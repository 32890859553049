footer {
	.row {
		display: flex !important;
		flex-wrap: wrap !important;
	}

	section {
		&.social-links {
			background: $social-links-background;
			height: 58px;
			@include media-breakpoint-down(sm) {
		    height: 75px;
			}
			.col-md-4{
				@media (min-width:768px) and (max-width:992px){
					max-width: 50%;
					padding-right: 0;
    				flex: 1;
				}
			}
			.follow {
				display: none;
				@include media-breakpoint-up(md) {
					display: block;
					color: $footer-color;
					vertical-align: middle;
					line-height: 58px;
					font-size: 15px;
					font-weight: 300;
				}
				@media (min-width:768px) and (max-width:992px){
					max-width: 50%;
				}
			}
			ul {
				display: flex;
				padding: 0px;
				margin: 0px;
				list-style: none;
				justify-content: center;
				padding-left: 20px;
				li {
					height: 40px;
					padding: 9px 20px 10px 0px;
					&.footer-blog{
						svg{
							width:46px;
						}
						@include media-breakpoint-down(sm) {
							padding-right:10px;
						}
					}
					@include media-breakpoint-down(sm) {
						height: 40px;
						padding: 0px 15px 0px 0px;
					}
					a {
						svg {
							width: 40px;
							height: 40px;
							path {
								fill: $social-icon-color;
							}
						}
						&:hover {
							svg {
								path {
									fill: lighten($social-icon-color, 20%);
								}
							}
						}
					}
				}
				@media (min-width:768px) and (max-width:992px){
					justify-content: flex-end;
					.footer-blog{
						padding-right: 0;
					}
				}
				img {
					max-height: 34px;
				}
			}
			@include media-breakpoint-down(sm) {
				> .container > .row {
					height:75px;
					align-items: center;
				}
			}
		}
		&.footer-copyright {
			padding: 15px 0px 10px;
			background: $legal-background;
			color: $white;
			.row {
				align-items: center;
			}
			.logo {
				text-align: right;
				margin: 5px 0;

				@include media-breakpoint-down(sm) {
					text-align: center;
					margin: 10px 0 !important;
				}
			}
		}
		&.footer-links {
			background: $footer-background;
			color: $footer-color;
			padding-top: 2.5em;
			padding-bottom: 45px;
			position: relative;
			@include media-breakpoint-down(sm) {
			padding-bottom: 30px;
		}
			a {
				color: $footer-color;
				font-weight: 400;
			}
			.optanon-toggle-display:hover {
				text-decoration: underline;
			}
			.nav-item {
				position: relative;
				h4 {
					@include font-body;
					border-bottom: 1px solid #fff;
					padding: 10px 0px;
					display: block;
					font-weight: 300;
					color: $footer-color;
					margin: 0;

					@include media-breakpoint-up(md) {
						border-bottom: 0px;
						padding: 0px 0px 15px 0px;
						font-size: 1.2em;
					}
					@include media-breakpoint-up(lg) {
						font-size: 1.4em;
					}
				}
				i {
					&.arrow {
						border: solid $white;
						border-width: 0 3px 3px 0;
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						padding: 3px;
						margin-top: 15px;
						transform: rotate(45deg);
						z-index: 1;
						&.down {
							transform: rotate(45deg);
						}
						&.up {
							transform: rotate(225deg);
							top: 5px;
						}
						@include media-breakpoint-up(md) {
							display: none; // Hide for medium plus displays
						}
					}
				}
			}
			ul.submenu {
				display: none;
				margin: 15px 0px;
				padding: 0px;
				@include media-breakpoint-up(md) {
					margin: 0px; // Hide for medium plus displays
				}

				list-style: none;
				&.show {
					display: block;
				}
				li {
					a {
						display: block;
						margin-bottom: 5px;
					}
				}
				@include media-breakpoint-up(md) {
					display: block;
				}
			}
			ul:first-child {
				display: block;
				margin: 0px;
				padding: 0px;
				list-style: none;
				@include media-breakpoint-up(md) {
					display: flex;
					li.group {
						flex-grow: 1;
						flex-basis: 16.66667%;
						display: block;
						line-height: 1.25em;
						margin: 10px 0;
					}
				}
			}
			.contact-details {
				position: relative;
				margin: 0px 0 0 0;
				padding: 0;
				list-style: none;
				@include media-breakpoint-down(sm) {
					position: relative;
					margin-top: 10px;
				}

				li {
					list-style: none;
				}

				.submenu {
					display: block;
				}

				.phone {
					display: flex;
					align-items: center;
					
					&::before {
						content: "";
						position: absolute;
						height: 30px;
						width: 30px;
						background: url("/images/uk/en/homepage/icons/telephone-#{$footer-phone}.svg");
						background-repeat: no-repeat;
					}
					span {
						padding: 0px 0px 0px 40px;
						font-weight: 700;
						@include media-breakpoint-up(lg) {
							font-size: 1.4em;
						}
						@include media-breakpoint-down(sm) {
							font-size: 1.8em;
					    }
					}
				}
				.contact {
					position: relative;
					padding-top: 20px;
					color: $footer-color;

					@include media-breakpoint-down(sm) {
					position: relative;
					padding-top: 10px;
				}

				h4 {
					@include font-body;
					padding: 10px 0px;
					display: block;
					font-weight: 300;
					color: $footer-color;
					margin: 0;

					@include media-breakpoint-up(md) {
						font-size: 1.2em;
						border-bottom: 0px;
						padding: 0px 0px 15px 0px;
					}
					@include media-breakpoint-up(lg) {
						font-size: 1.4em;
					}
				}
				}
				.submenu-contact {
					margin: 5px 0px;
					padding-left: 0px;
				 }	
			}
		}
		&.carbon-neutral-delivery {
			background: #537E30;
			color: white;
			padding: 10px 0px 15px;
			svg {
				max-width: 450px;
			}
			.carbon-neutral-delivery-right-container {
				@media (max-width:991px) { 
					margin-top: 13px;
				}
				.carbon-top-text {
					font-family: 'Noto Serif',sans-serif;
					@media (max-width:991px) { 
						font-size: 15px;
					}
				}
				.carbon-bottom-text {
					font-size: 13px;
					margin-top: 3px;
				}
			}
			@media (max-width:991px) {
				text-align: center;
			}
			@media (max-width:768px) {
				text-align: left;
			}
		}
	}
}

.stw{
	footer{
		.social-links{
			ul{
				li{
					a{
						svg{
							width:46px;
						}
					}
				}
			}
		}
	}
}
.avy{
	footer{
		.social-links{
			ul{
				li{
					@media (min-width:768px) {
						padding:15px 30px 0px 0;
					}
					@include media-breakpoint-down(sm) {
						padding: 8px 30px 0px 0px;
					}
				}
			}
		}
		section.footer-links{
			.contact-details{
				.phone{
					&::before {
						background: url("/images/uk/en/homepage/icons/telephone.svg");
						background-repeat: no-repeat;
						height: 24px;
						width: 24px;
					}
					span{
						font-size: 16px;
						font-weight: 500;
					}
				}
				li{
					margin-bottom: 20px;
				}
			}
		}
	}			
}