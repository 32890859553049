$color-options: (
    orange: #d07d17,
    green: #9bab4d,
    pink: #df897c,
    red: $red,
    black: #000,
    blue: $blue,
    teal: #409090,
    sage: $leaf-green,
    gold: #a89054,
);

#navigation-overlay {
    background-color: $blue;
}

.big-strip {
    background-color: #f3a72e;
}

header#new-homepage-test-header {
    div.logo {
        polygon,
        path,
        rect {
            fill: $red;
        }
    }
    #cart {
        span.number-of-items {
            background: $navy-blue;
        }
    }
    .masthead {
        .cart{
            svg {
                path {
                    fill: $leaf-green;
                }
            }
        }
    }
    &.is-sticky {
        .masthead {
            #cart {
                .number-of-items {
                    background: $navy-blue;
                }
                svg {
                    path {
                        fill: $leaf-green;
                    }
                }
            }
        }
    }
    #main-navigation {
        ul {
            li {
                .nav-item {
                    color: $black;
                    i {
                        border-color: $red;
                    }
                }
                &.open-nav>.nav-item {
                    background: $red;
                    a {
                        color: $white;
                    }
                }
                &.open-nav>.submenu>ul>li.open-nav>.nav-item {
                    background: $red;
                }
            }
        }
        &.nav-intended {
            ul {
                li {
                    .submenu {
                        background: $dark-cream;
                        border: none;
                        border-image: none;

                        &::before {
                            content: "";
                            height: 1px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: -1px;
                            background: $dark-cream;
                        }

                        a {
                            font-family: $font-family-roboto;
                            &:hover {
                                color: $red;
                            }
                            strong {
                                color: $red;
                                @include media-breakpoint-down(sm){
                                    color: #000000;
                                }
                            }
                            &.highlight {
                                color: $red;
                                span.fa-chevron-right {
                                    color: $red;
                                }
                                &.bold {
                                    font-weight: 600;
                                }
                            }
                        }
                        ul.linkmenu {
                            .nav-item {
                                @include media-breakpoint-up(sm) {
                                color: $red !important;
                                font-family: $font-family-oswald;
                                text-transform: uppercase;
                                }
                            }
                        }
                        @media (max-width:767px) {
                            .nav-item {
                                a {
                                    &:hover {
                                        color: inherit;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.expanded {
            nav {
                background: $dark-cream;
            }
        }
    }
}

#main-navigation {
    background: $dark-cream;
}

@include media-breakpoint-down(sm) {
    header#new-homepage-test-header,
    header#simple-header {
        background-color: $dark-cream;
        height: 57px;
        padding: 18px 8px 8px 8px;

        .header-line {
            top: 57px;
        }
        &.expanded {
            height: 57px;
            .logo a {
                height: 57px;
                width: 57px;
            }
        }

        @include media-breakpoint-down(sm) {
            div.logo {
                top: 3px;
                margin-left: -18px;
                width: auto !important;
                a {
                    height: 50px;
                    width: 50px;
                }
            }
        }

        &.is-sticky {

            @include media-breakpoint-down(sm) {
                div.logo {
                    top: 6px;
                    height: 50px;
                    width: 50px;
                    margin-left: -18px;
                }
            }

            &.is-sticky {
                height: 57px;
            }
        }
    }
}

@media (min-width: 992px) { 
    header#new-homepage-test-header {
        #main-navigation {
            ul {
                li {
                    &.nav-top-level {
                        &:hover {
                            border-color: $red;
                            color: $red;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    header#new-homepage-test-header {
        div.logo {
            top: 46px;
        }
        .masthead {
            .old-search {
                svg {
                    path {
                        fill: $white;
                    }
                }
            }            
        }
        #main-navigation {
            ul {
                li {
                    &.nav-top-level {
                        font-family: $font-family-noto-serif;
                        border-bottom-color: $dark-cream;
                        &:hover, &.selected {

                            a {
                                &:first-of-type {
                                    background: transparent;
                                }
                            }
                            & > .submenu {
                                background: $dark-cream;
                                border: none;
                                border-image: none;
                                li {
                                    .nav-item {
                                        color: $red;
                                        text-transform: uppercase;
                                        font-family: $font-family-oswald;
                                    }
                                }
                            }
                        }
                        &.selected {
                            border-color: $red;
                            color: $red;
                        }
                    }
                    &.flipper-container {
                        height: 20px;
                    }
                }
            }
            border-top: 1px solid $red !important;
        }
        &.is-sticky {
            .desktop-search-panel {
                .open {
                    background-color: $red;
                }
            }
        }
    }
}

// Search
.desktop-search-panel {
    .old-search {
        button[type="submit"] {
            
                border: none;
                background: $red;
                border: 1px solid #a3a3a3;
                @media (min-width: 768px){
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            
        }
        input[type="text"]{
            @include media-breakpoint-up(lg){
                // width: 223px;
            }
            min-height: 40px;
        }
            &.focus{
                :not(#algolia-autocomplete) {
                button[type="submit"] {
                    @media (min-width: 768px){
                        height: 44px;
                        width: 44px;
                    }
                }
            }
        }
    }
}

section.guarantee {
    background: $dark-cream;
    .bootstrap-4 {
        background: $dark-cream;
    }
}

// Footer
footer {
    section {
        &.footer-links {
            background: #333333;
            .nav-item h4 {
                font-weight: 600;
                text-transform: uppercase;
                @media (min-width: 768px) {
                    font-family: $font-family-oswald;
                }
            }
            .contact-details {
                .contact h4,
                .phone span {
                    font-weight: 600;
                    @media (min-width: 768px) {
                        font-family: $font-family-oswald;
                    }
                }
            }
        }
        &.footer-copyright {
            background: $black;
            .logo {
                margin: 5px 0;
            }
            .copy {
                a { 
                    color: inherit !important;
                }
            }
        }
        &.social-links {
            background: $purple;
            text-transform: uppercase;
            svg {
                &.icon-social-facebook {
                    .cls-1 {
                        fill: $dark-cream;
                    }
                    .cls-2 {
                        fill: $purple;
                    }
                }
                &.icon-social-twitter {
                    .cls-1 {
                        fill: $dark-cream;
                    }
                    .cls-2 {
                        fill: $purple;
                    }
                }
                &.icon-social-instagram {
                    .cls-1 {
                        fill: $dark-cream;
                    }
                    .cls-2 {
                        fill: $purple;
                    }
                }
                &.icon-social-youtube {
                    .cls-1 {
                        fill: $dark-cream;
                    }
                    .cls-2 {
                        fill: $purple;
                    }
                }
            }
            .follow {
                font-family: $font-family-oswald;
                font-size: 3rem;
                font-weight: 600;
            }
            ul {
				li {
					height: 40px;
                }
            }
        }
    }
}

section.quick-links {
    a.btn {
        color: $red;
        border-color: $red;
        font-family: $font-family-oswald;
        font-size: 2.4rem;
        &:hover,
        &:focus,
        &:active {
            border-color: $red;
            background-color: $red;
            color: white;
        }
        .arrow.right { 
            top: -2px;
            @include media-breakpoint-only(md) {
				top:-1px;
			}
            @include media-breakpoint-down(xs) {
                top: 41%
            }
        }
    }
}

section.recently-viewed {
    & > div.container {
        min-height: 200px;
    }
    @include media-breakpoint-up(md) {
        .scroll {
            &.left,
            &.right {
                border-color: $red;
            }
        }
    }
}

section.media-content {
    h4 {
        font-family: $font-family-oswald;
        text-transform: uppercase;
    }
    // generate all the colour variables
    div[class^="block-"] {
        .text {
            h4 {
                font-weight: 600;
            }
        }
        @each $key, $val in $color-options {
            .text.#{$key} {
                background-color: #{$val};
            }
            .btn.#{$key} {
                background-color: #{$val};
                border-color: $white;
                &:hover {
                    background: rgba(0,0,0,0.4);
                }
            }
        }
    }
}

section.recommended {
    background: $dark-cream;
    color: $black;
    & > div.container {
        min-height: 400px;
    }
    .arrow {
        &.right {
            border-color: $black;
        }
    }
    .scroll.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    .view-all {
        a {
            color: $black;
        }
    }
    @include media-breakpoint-up(md) {
        .scroll {
            &.left,
            &.right {
                border-color: $red;
            }
        }
    }
    .window {
        @include media-breakpoint-up(md) {
            border-color: $dark-cream;
        }
        li a {
            .button {
                background: $leaf-green;
                color: $white;
            }
            .name {
                color: $black;
            }
            .image {
                background: none;
            }
            .rating {
                i.fa-star {
                    color: #c28926;
                }
            }
        }
    }
    h2 {
        font-size: 28px;
    }
}

.hero {
    .hero-panel {
        @include media-breakpoint-up(md) {
            padding: 0;
            a {
                margin: 0;
                border-radius: 0px;
            }
        }
        img {
            @include media-breakpoint-up(md) {
                height: 320px;
            }
            @include media-breakpoint-up(lg) {
                height: 432px;
            }
        }
    }
    .tab {
        @include media-breakpoint-up(md) {
            &::before {
                left: -26px;
            }
        }
    }
}

.delivery-option-panel {
    .color-red {
        fill: #CF004f;
    }
}
@media (max-width: 768px) {
    section.recommended,
    section.recently-viewed {
        .window {
            ul {
               transition: transform .5s ease-in-out;
            }
            li{
                margin: 15px 10px 0 10px;
                @media (max-width: 350px) { 
                    margin-right: 8px;
                    margin-left: 8px;
                }
            }
        }
        .scroll {
            border: solid $gray-700;
            border-width: 0 5px 5px 0;
            display: block;
            position: absolute;
            top: 62%;
            margin-top: -5px;
            padding: 10px;
            z-index: 1;
            cursor: pointer;
            opacity: 1;

            &.left{
                left: 35%;
                -webkit-transform: rotate(-225deg);
                transform: rotate(-225deg);
            }
            &.right{
                right: 35%;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &.recent-left{
                top: 42%;
                left: 2rem;
            }
            &.recent-right{
                top: 42%;
                right: 2rem;
            }

            &.left, &.right {
                border-color: #cf004f;
            }
        }
    }
    section.recommended {
        .scroll {
            top: auto;
            bottom: 11.5%;
            padding: 9px;
            margin-top: -5px;
        }
        .slider-dots {
            text-align: center;
            padding:0 3rem;
            margin: 3rem 0px;
            span {
                display: inline-block;
                margin: 0 0.2rem;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                border: 0.1rem solid #487973;
                cursor: pointer;
                vertical-align: middle;
                &.active {
                    background-color: #cf004f;
                    border-color: transparent;
                }
            }
        }
    }
}
section.recommended{
    .scroll {
        @media (max-width: 500px) { 
            &.left{
                left: 30%;
            }
            &.right{
                right: 30%;
            }
        }
        @media (max-width: 380px) { 
            bottom: 12.85%;
            &.left{
                left: 25%;
            }
            &.right{
                right: 25%;
            }
        }
        @media (max-width: 335px) {
            bottom: 12.25%;
        } 
    }
}

//Upsell
.signup-button-container {
    background: $dark-cream;
}

#algolia-autocomplete {
    .aa-SubmitIcon {
        path {
            fill: $red!important;
        }
    }
}

