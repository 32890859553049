$color-options: (
	orange: #d07d17,
	green: #9bab4d,
	pink: #df897c,
	red: #6f162c,
	black: #000,
	blue: #005b7c,
	teal: #409090,
	sage: #395b4d,
	gold: #a89054
);

.primary-section {
	@include media-breakpoint-up(md) {
		padding-top: 12px;
	}
}

section.media-content {
	.row {
		display: flex !important;
		flex-wrap: wrap !important;
	}

	padding: 2.5em 0em 2.5em 0em;

	@include media-breakpoint-down(sm) {
		padding: 2em 0 1.5rem 0;
	}

	h2 {
		text-align: center;
		margin-bottom: 40px;
		font-weight: 500;
	}

	div {
		height: 100%;
	}

	.content {
		@include media-breakpoint-up(lg) {
			height: 480px;
		}
		@include media-breakpoint-down(md) {
			height: 400px;
		}
		@include media-breakpoint-down(sm) {
			height: auto;
		}
	}

	// match all the block-* selectors
	div[class^="block-"] {
		position: relative;
		width: 100%;

		img {
			object-fit: cover;
		}

		.text {
			color: #fff;
			text-align: center;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			font-weight: 400;
			font-size: 0.9em;
			padding:1.5rem;

			h4,
			p {
                @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                    // IE11 fix
                    width: 100%;
                 }
                 width:auto;
            }

			h4 {
				font-size: 1.8em;
				font-weight: 500;
				strong {
					font-weight: 500;
					text-transform: uppercase;
				}
			}
			p{
				margin-bottom:1.7rem;
			}
			// hide all the content when in mobile
			@include media-breakpoint-down(sm) {
				padding:1.2rem;
				justify-content: center;
				h4{
					margin-bottom:1.5rem;
				}
				p {
					display: none;
				}
			}
			//font changes for ipad
			@include media-breakpoint-down(md) {
				font-size: 0.8em;
				h4{
					font-size: 1.6em;
				}
				p{
					margin-bottom:1.4rem;
				}
			}
			@include media-breakpoint-only(md) {
				padding:1.3rem;
			}

			// .btn {
			// 	width: 100%;
			// 	font-size: 1em;
			// 	font-weight: 500;
			// 	white-space: normal;
			// 	max-width: 240px;
			// 	line-height: 1.3;
			// 	padding:8px;
			// 	&:hover{
			// 		background-color: rgba(255,255,255,0.1);
			// 		color:#f8f9fa;
			// 	}
			// }
		}
		// generate all the colour variables
		@each $key, $val in $color-options {
			.text.#{$key} {
				background-color: #{$val};
			}
		}

		// @each $key, $val in $contentful-colors {
		// 	.text.contentful-#{$key} {
		// 			background-color: #{$val};
		// 	}
		// 	.btn.contentful-#{$key} {
		// 			background-color: #{$val};
		// 			border-color: $white;
		// 			&:hover {
		// 					background: rgba(0,0,0,0.4);
		// 			}
		// 	}
		// }
		.text.contentful-transparent {
				background-color: transparent;
		}
		.btn.contentful-transparent {
				background-color: transparent;
				border-color: $white;
		}

		div.text a, span.text span.btn {
			@media screen and (min-width:768px) {
				white-space: initial;
				line-height: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.block-image-button{
		.text{
			.btn-outline-light{
				left:auto!important;
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					left:0!important;
					@include media-breakpoint-down(sm) {
						left:auto!important;
						position: relative!important;
					}
				}
			}
		}
	}

	.block-square {
		position: relative;

		&::after {
			content: "";
			display: block;
			padding-bottom: 100%;
		}

		.text {
			position: absolute;
			width: 100%;
			height: 100%;

			p {
				display: block !important; // The fact that this is needed is very frustrating.
			}
		}
	}

	.block-square-image-large,
	.block-image-right,
	.block-image-left,
	.block-image-top,
	.block-image-bottom,
	.block-image-button {
		img {
			height: 100%;
			object-fit: cover;
			width: 50%;
			@media screen and (max-width: 767px) {
				max-width: 100%;
				aspect-ratio: 1 / 1;
			}
		}

		.text {
			width: 50%;
			top: 0;
			bottom: 0;
			position: absolute;
		}
		.btn{
			padding: 8px !important;
		}
		// for mobile this should default to a block-image-square
		@include media-breakpoint-down(sm) {
			img {
				width: 100%;
				margin: 0 !important;
			}

			.text {
				width: 100%;
				top: auto;
				left: 0;
				right: 0;
				height: auto;
			}
		}
	}

	.block-square-image-large {
		img {
			width: 100%;
		}

		.text {
			bottom: 0;
			left: 0;
			right: 0;
			padding: 1em;
			position: absolute;
			text-align: center;
			height: auto;
			width: 100%;
			top: auto;
			display: block;
		}
	}

	.block-image-right img {
		margin-left: 50%;
	}
	.block-image-right .text {
		left: 0;
	}
	.block-image-left img {
		margin-right: 50%;
	}
	.block-image-left .text {
		right: 0;
	}
	.block-image-top h4{
		margin-top:5px;
	}
	.block-image-top img {
		width: 100%;
		object-fit: cover !important;
		object-position: 50%;
		height: 50%;

		@include media-breakpoint-down(sm) {
			object-position: initial;
		}

		@include media-breakpoint-down(sm) {
			height: 100%;
		}
	}
	.block-image-top .text {
		bottom: 0;
		width: 100%;
		top: auto;
		height: 50%;
	}
	.block-image-bottom img {
		margin-top: 0;
	}
	.block-image-bottom .text {
		top: 0;
		width: 100%;
		bottom: auto;
		height: 50%;
	}
	.block-image-button img {
		width: 100%;
	}
	.block-image-button .text {
		width: auto;
		left:20px;
		right:20px;
		@include media-breakpoint-only(md) {
			width: 91%;
			left: 8px;
		}
	}

	@include media-breakpoint-down(sm) {
		.block-image-top .text {
			height: auto;
		}
		.block-image-bottom .text {
			height: auto;
		}
		&.latest-offers-section .block-image-top a {
			width: 100%;
			display: block !important;
			picture {
				display: block;
			}
		}
	}

	&.latest-offers-section  .block-image-top a .latest-offer-img {
		@include media-breakpoint-up(md) {
			object-position: center -180px;
		}
		@include media-breakpoint-up(lg) {
			height: auto;
		}
		@include media-breakpoint-only(md) {
			padding: 0 20px;
		}
	}
}

.bbc{
	.media-content{
		.content{
			div[class^="block-"]{
				.text{
					h4{
						font-weight: 300;
						line-height: 1.3;
						font-size: 1.6em;
						margin-bottom: .6rem;
						@include media-breakpoint-only(md) {
							font-size: 1.5em;
						}
						@include media-breakpoint-down(sm) {
							font-size: 1.4em;
							margin-bottom: 1.3rem;
						}
					}
					p{
						margin-bottom: 1.7rem;
						@include media-breakpoint-only(md) {
							margin-bottom: 1.4rem;
						}
						@include media-breakpoint-down(sm) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	section{
		&.newsletter,&.media-content,&.recommended{
			h2{
				font-weight: 300;
				font-size: 1.8em;
			}
		}
	}
}

.stw {
	section {
		&.recently-viewed, &.media-content {
			h2 {
				@include font-heading(1.8em);
				font-weight: 600;
			}
		}
	}
}

.avy {
	section {
		&.recently-viewed, &.media-content {
			h2 {
                @include font-heading(24px);
                font-weight: 600;
				font-family: 'Libre Caslon Text', sans-serif;
                @media (max-width:960px) {
                    font-size: 22px;
                }
            }
		}
	}
}
