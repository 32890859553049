@mixin font-size($sizeValue: 1.6) {
//   font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

// body {
// 	color: grey(900);
// 	font-family: "Roboto",sans-serif;
//     font-display: auto;
// 	line-height: 1.5em;
//     font-size: 1rem;
// }

@mixin font-heading($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $headings-font-family;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

@mixin font-body($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: $font-family-base;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}

@mixin font-montserrat($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: 'Montserrat', sans-serif;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
}



h1 {}
h2 {}
h3 {}
h4 {
    @include font-heading(16px);
}